import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => (
    {
        title: {
            color: '#1E2E3A',
            fontSize: 28,
            fontWeight: 'bold',
            borderBottomColor: '#00B17C',
            borderBottomWidth: 3,
            borderBottomStyle: "solid"
        },
        subtitle: {
            color: '#1E2E3A',
            fontSize: 20,
            fontWeight: 'bold',
            borderBottomColor: '#00B17C',
            borderBottomWidth: 3,
            borderBottomStyle: "solid"
        },
        containerButton: {
            margin: 10,
            textAlign: "right"
        },
        table: {
            width: '100%'
        },
        datePickerMargin: {
            marginLeft: 32
        },
        fullWidth: {
            width: '100%'
        },
        paper: {
            position: 'absolute',
            width: '80%',
            height: 600,
            backgroundColor: 'white',
            padding: 10,
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            
        },
        titleModal: {
            textAlign: "center"
        },
        termo: {
            overflow: 'scroll',
            height: 400
        },
        sinal: {
            marginLeft: 30
        },
        tipoViolenciaContainer : {
            marginBottom: 20,
            marginTop: 30,
            border: 1,
            borderColor: '#cecece',
            borderStyle: 'solid',
            padding: 10,
            borderRadius: 10
        },
        selectedItem: {
            border: 1,
            borderColor: '#cecece',
            borderStyle: 'solid',
            padding: 10,
            margin: 0
        },
        titleContainer: {
            backgroundColor: "#00B17C",
            padding: 10,
            margin:  0,
            textAlign: 'center',
            fontWeight: 'bold'
        },
        tipologiasContainer: {
            marginLeft: 20
        },
        subTipologiasContainer: {
            marginLeft: 20
        },
        textCenter: {
            textAlign: 'center'
        },
        containerItems: {
            marginBottom: 20
        },
        alunoContainer: {
            marginLeft: 30,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#CECECE",
            padding: 10,
            borderRadius: 10
        },
        temFeedback: {
            background: '#81c784'
        },
        semFeedback: {
            background: '#e57373'
        },
        containerNotificacao: {
            margin: 10
        }
    }
))