import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { TextField, Box, Grid, Button } from '@material-ui/core'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useParams, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'

export default function GestorMunicipal(){
    const classes = style()
    const { id } = useParams()
    const history = useHistory()
    const [dados, setDados] = useState({
        nome: '',
        matricula: '',
        nascimento: '',
        cidade: '',
        estado: '',
        cpf: '',
        secretaria: ''
    })

    const [errors, setErrors] = useState({
        nome: false,
        matricula: false,
        nascimento: false,
        cidade: false,
        estado: false,
        cpf: false,
        secretaria: false
    })

    const save = () => {
        validate(dados)

        const data = {
            ...dados
        }

        const nascimento = data.nascimento.split("/")
        data.nascimento = `${nascimento[1]}/${nascimento[0]}/${nascimento[2]}`

        if(data.id){
            axios
                .put(`gestor/${data.id}`, data)
                .then(({data}) => {
                    Swal.fire("Registro salvo com sucesso.")
                    history.push("/gestorMunicipal")
                })
                .catch(err => {
                    Swal.fire("Falha ao salvar os dados. Tente novamente.")
                })
        }else{
            axios
                .post("gestor", data)
                .then(({data}) => {
                    Swal.fire("Registro inserido com sucesso. A nova senha é: " + data.senha)
                    history.push("/gestorMunicipal")
                })
                .catch(err => {
                    Swal.fire("Falha ao salvar os dados. Tente novamente.")
                })
        }
    }

    useEffect(() => {
        axios
            .get(`/gestor/${id}`)
            .then(({data}) => {
                setDados((data.data)[0])
            })
            .catch(err => {
                console.log(err)
            })
    }, [id])

    const validate = (dados) => {
        let localErrors = errors

        if(dados.nome === ''){
            localErrors.nome = 'Nome'
        }

        if(dados.matricula === ''){
            localErrors.matricula = 'Matricula'
        }

        if(dados.cpf === ''){
            localErrors.cpf = 'CPF'
        }

        if(dados.nascimento === ''){
            localErrors.nascimento = 'Data de Nascimento'
        }

        if(dados.cidade === ''){
            localErrors.cidade = 'Cidade'
        }

        if(dados.estado === ''){
            localErrors.estado = 'Estado'
        }

        if(dados.secretaria === ''){
            localErrors.secretaria = 'Secretaria'
        }

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Gestor Municipal</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Matrícula" size="small" fullWidth error={errors.matricula ? true : false} value={dados.matricula} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    matricula: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Secretaria" size="small" fullWidth error={errors.secretaria ? true : false} value={dados.secretaria} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    secretaria: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CPF" size="small" fullWidth error={errors.cpf ? true : false} value={dados.cpf} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cpf: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Data de nascimento"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            nascimento: value
                                        })
                                    }}
                                    error={errors.nascimento ? true : false}
                                    inputValue={dados.nascimento}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} value={dados.cidade} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => save()}>
                          Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}