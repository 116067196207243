import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import style from './style'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../Services/axios'
import swal from 'sweetalert'
import { Button } from '@material-ui/core'

export default function(){
    const classes = style()
    const {id} = useParams()
    const [historico, setHistorico] = useState(null)
    const history = useHistory()

    useEffect(() => {
        axios
            .get(`aluno/${id}/historico`)
            .then(({data}) => setHistorico(data))
            .catch(err => {
                swal("Falha ao carregar histórico do aluno.")
            })
    }, [id])

    console.log(historico)

    return (
        <>
            <Typography className={classes.title} variant="h1" >Histórico do aluno</Typography>

            {
                historico ? (
                    <>
                        <p><strong>Aluno: </strong>{historico.aluno.nome}</p>
                        <p><strong>Quantidade de notificações: </strong>{historico.notificacoes.length}</p>
                        <h4>Notificações do aluno:</h4>
                        <div>
                            {
                                historico.notificacoes.map(not => (
                                    <div key={not.id} className={classes.notificacao}>
                                        <p>Data: {not.dataNotificacao}</p>
                                        <p>Origem: {not.origemViolencia}</p>
                                        <p>Quantidade de feedbacks: {not.quantidadeFeedbacks}</p>
                                        <p><Button variant="outlined" color="primary" onClick={() => {
                                            history.push("/notificacao/" + not.id + "/visualizar")
                                        }}>Ver Mais</Button></p>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : 
                    (<p>Carregando...</p>)
                
            }
        </>
    )
}