import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import style from './style'

import { TextField, Box, Grid, Button, MenuItem } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import SelectBox from '../../Components/SelectBox'

export default function CadastrarAluno(props){

    const { instituicao_id, id } = useParams()
    const [instituicaoEducacionalList, setInstituicaoEducacionalList] = useState([])
    const [resideComList, setResideComList] = useState([])
    const [tiposDeficienciaList, setTiposDeficienciaList] = useState([])
    const [vinculoResponsavelList, setVinculoResponsavelList] = useState([])

    useEffect(() => {
        axios
            .get("instituicao")
            .then(({data}) => setInstituicaoEducacionalList(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar lista de instituições, recarregue a página.")
            })

        axios
            .get("vinculoResponsavel")
            .then(({data}) => setVinculoResponsavelList(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar lista de vínculos com responsável. Recarregue a página.")
            })

        axios
            .get("resideCom")
            .then(({data}) => setResideComList(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar lista de dados. Recarregue a página.")
            })

        axios
            .get("tiposDeficiencia")
            .then(({data}) => setTiposDeficienciaList(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar lista de dados. Recarregue a página.")
            })
    }, [])

    useEffect(() => {
        if(id){
            axios
                .get(`aluno/${id}`)
                .then(({data}) => setDados((data.data)[0]))
                .catch(err => {
                    console.log(err)
                })
        }
    }, [id])

    const classes = style()
    const [dados, setDados] = useState({
        instituicao_id: instituicao_id || 0,
        cpf: '',
        nome: '',
        nascimento: '',
        mae: '',
        pai: '',
        vinculoResponsavel: '',
        vinculoResponsavelOutro: '',
        responsavel: '',
        resideCom: '',
        resideComOutro: '',
        numero: '',
        bairro: '',
        estado: '',
        cidade: '',
        rua: '',
        cep: '',
        complemento: '',
        referencia: '',
        telefone1: '',
        telefone2: '',
        telefone3: '',
        deficiencia: '',
        deficienciaOutro: '',
        anoMatriculado: '',
        periodoEstudo: '',
        tempoNaInstituicao: '',
        revitimizacao: ''
    })

    const validations = {
        instituicao_id: 'number|required',
        cpf: 'string|required',
        nome: 'string|required',
        nascimento: 'string|required',
        mae: 'string|required',
        pai: 'string|required',
        vinculoResponsavel: 'number|required',
        responsavel: 'string|required',
        resideCom: 'number|required',
        numero: 'number|required',
        bairro: 'string|required',
        estado: 'string|required',
        cidade: 'string|required',
        rua: 'string|required',
        cep: 'string|required',
        complemento: 'string',
        referencia: 'string',
        telefone1: 'string|required',
        deficiencia: 'number|required',
        anoMatriculado: 'string|required',
        periodoEstudo: 'string|required',
        tempoNaInstituicao: 'string|required',
        revitimizacao: 'string|required'
    }
    
    const INITIAL_ERRORS = {
        instituicao_id: 0,
        cpf: false,
        nome: false,
        nascimento: false,
        mae: false,
        pai: false,
        vinculoResponsavel: false,
        responsavel: false,
        resideCom: false,
        numero: false,
        bairro: false,
        estado: false,
        cidade: false,
        rua: false,
        cep: false,
        complemento: false,
        referencia: false,
        telefone1: false,
        deficiencia: false,
        anoMatriculado: false,
        periodoEstudo: false,
        tempoNaInstituicao: false,
        revitimizacao: false
    }
    const [errors, setErrors] = useState(INITIAL_ERRORS)

    const save = () => {
        validate(dados)

        const data = dados

        const nascimento = data.nascimento.split("/")
        data.nascimento = `${nascimento[1]}/${nascimento[0]}/${nascimento[2]}`

        axios
            .post("aluno", data)
            .then(() => Swal.fire("Salvo com sucesso"))
            .catch(err => {
                console.log(err)
                Swal.fire("Verifique os dados e tente novamente.")
            })
    }

    const validate = (dados) => {

        let localErrors = INITIAL_ERRORS

        const keys = Object.keys(validations)

        keys.forEach(key => {
            const constraints = validations[key].split('|')
            constraints.forEach(constraint => {
                const dado = dados[key]
                switch(constraint){
                    case 'string':
                        if(typeof dado !== 'string'){
                            localErrors[key] = key
                        }
                    break;

                    case 'number':
                        if(typeof dado !== 'number'){
                            localErrors[key] = key
                        }
                    break;
                    
                    case 'required':
                        if(dado === '' || dado === 0){
                            localErrors[key] = key
                        }
                    break;

                    default:
                        break;
                }
            })
        })

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    const revitimizacaoList = [
        { 
            id: "Sim",
            label: "Sim"
        },
        { 
            id: "Não",
            label: "Não"
        },
        { 
            id: "Ignorado",
            label: "Ignorado"
        }
    ]

    const periodoEstudoList = [
        {
            id: "Matutino",
            label: "Matutino"
        },
        {
            id: "Vespertino",
            label: "Vespertino"
        },
        {
            id: "Período Integral",
            label: "Período Integral"
        },
        {
            id: "Noturno",
            label: "Noturno"
        }
    ]

    const handleChangeValue = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setDados({
            ...dados,
            [field]: value
        })
    }

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Alunos</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Instituição Educacional" variant="outlined" size="small" error={errors.instituicao_id ? true : false} value={dados.instituicao_id} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    instituicao_id: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    instituicaoEducacionalList.map(instituicao => (
                                        <MenuItem value={instituicao.id} key={instituicao.id}>{instituicao.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box component="div" m={4}>
                            {/* <TextField variant="outlined" label="Houve Revitimização outras vezes?" size="small" fullWidth error={errors.revitimizacao ? true : false} value={dados.revitimizacao} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    revitimizacao: event.target.value
                                })
                            }} /> */}
                            <SelectBox
                                label={"Houve Revitimização outras vezes?"}
                                error={errors.revitimizacao}
                                onChange={handleChangeValue}
                                name={"revitimizacao"}
                                value={dados.revitimizacao}
                                items={revitimizacaoList}
                            >

                            </SelectBox>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CPF" size="small" fullWidth error={errors.cpf ? true : false} value={dados.cpf} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cpf: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Data de nascimento"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            nascimento: value
                                        })
                                    }}
                                    error={errors.nascimento ? true : false}
                                    inputValue={dados.nascimento}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Mãe" size="small" fullWidth error={errors.mae ? true : false} value={dados.mae} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    mae: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Pai" size="small" fullWidth error={errors.pai ? true : false} value={dados.pai} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    pai: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Reside Com" variant="outlined" size="small" error={errors.resideCom ? true : false} value={dados.resideCom} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    resideCom: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    resideComList.map(resideCom => (
                                        <MenuItem value={resideCom.id} key={resideCom.id}>{resideCom.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                    {
                        [3, 5, 7].includes(dados.resideCom) ? (
                            <Grid item xs={12} sm={6}>
                                <Box component="div" m={4}>
                                    <TextField variant="outlined" label="Descreva com quem o aluno reside" size="small" fullWidth error={errors.resideComOutro ? true : false} value={dados.resideComOutro} onChange={(event) => {
                                        setDados({
                                            ...dados,
                                            resideComOutro: event.target.value
                                        })
                                    }} />
                                </Box>
                            </Grid>
                        ) : null
                    }
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Vínculo com Responsável" variant="outlined" size="small" error={errors.vinculoResponsavel ? true : false} value={dados.vinculoResponsavel} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    vinculoResponsavel: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    vinculoResponsavelList.map(vinculoResponsavel => (
                                        <MenuItem value={vinculoResponsavel.id} key={vinculoResponsavel.id}>{vinculoResponsavel.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                    {
                        [3, 5, 7].includes(dados.vinculoResponsavel) ? (
                            <Grid item xs={12} sm={6}>
                                <Box component="div" m={4}>
                                    <TextField variant="outlined" label="Descreva o vínculo com o Responsável" size="small" fullWidth error={errors.vinculoResponsavelOutro ? true : false} value={dados.vinculoResponsavelOutro} onChange={(event) => {
                                        setDados({
                                            ...dados,
                                            vinculoResponsavelOutro: event.target.value
                                        })
                                    }} />
                                </Box>
                            </Grid>
                        ) : null
                    }
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome do Responsável" size="small" fullWidth error={errors.responsavel ? true : false} value={dados.responsavel} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    responsavel: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>
                
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} value={dados.cidade} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Logradouro" size="small" fullWidth error={errors.rua ? true : false} value={dados.rua} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    rua: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Número" size="small" fullWidth error={errors.numero ? true : false} value={dados.numero} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    numero: parseInt(event.target.value)
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Bairro" size="small" fullWidth error={errors.bairro ? true : false} value={dados.bairro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    bairro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CEP" size="small" fullWidth error={errors.cep ? true : false} value={dados.cep} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cep: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Complemento" size="small" fullWidth error={errors.complemento ? true : false} value={dados.complemento} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    complemento: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Referência" size="small" fullWidth error={errors.referencia ? true : false} value={dados.referencia} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    referencia: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 1" size="small" fullWidth error={errors.telefone1 ? true : false} value={dados.telefone1} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone1: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 2" size="small" fullWidth error={errors.telefone2 ? true : false} value={dados.telefone2} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone2: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 3" size="small" fullWidth error={errors.telefone3 ? true : false} value={dados.telefone3} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone3: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Deficiência" variant="outlined" size="small" error={errors.deficiencia ? true : false} value={dados.deficiencia} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    deficiencia: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    tiposDeficienciaList.map(deficiencia => (
                                        <MenuItem value={deficiencia.id} key={deficiencia.id}>{deficiencia.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                    {
                        dados.deficiencia === 8 ? (
                            <Grid item xs={12} sm={6}>
                                <Box component="div" m={4}>
                                    <TextField variant="outlined" label="Descreva a deficiência" size="small" fullWidth error={errors.deficienciaOutro ? true : false} value={dados.deficienciaOutro} onChange={(event) => {
                                        setDados({
                                            ...dados,
                                            deficienciaOutro: event.target.value
                                        })
                                    }} />
                                </Box>
                            </Grid>
                        ) : null
                    }
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Ano que está matriculado" size="small" fullWidth error={errors.anoMatriculado ? true : false} value={dados.anoMatriculado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    anoMatriculado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            {/* <TextField variant="outlined" label="Período de estudo" size="small" fullWidth error={errors.periodoEstudo ? true : false} value={dados.periodoEstudo} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    periodoEstudo: event.target.value
                                })
                            }} /> */}

                            <SelectBox
                                label={"Período de estudo"}
                                error={errors.periodoEstudo}
                                onChange={handleChangeValue}
                                name={"periodoEstudo"}
                                value={dados.periodoEstudo}
                                items={periodoEstudoList}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Quanto tempo estuda na instituição?" size="small" fullWidth error={errors.tempoNaInstituicao ? true : false} value={dados.tempoNaInstituicao} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    tempoNaInstituicao: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => save()}>
                          Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}