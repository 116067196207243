import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from '../../Services/axios';
import swal from 'sweetalert'
import { useHistory } from 'react-router-dom'
import LocalStorage from '../../Services/LocalStorage'
import jwtDecode from 'jwt-decode'

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();

  const [dados, setDados] = useState({
      usuario: '',
      senha: ''
  });

  function handleChangeText(event){
      setDados({
          ...dados,
          [event.target.name]: event.target.value
      })
  }

  function handleSubmit(event){
    event.preventDefault()

    const { usuario, senha } = dados

    axios.post('login', {
      usuario,
      senha
    })
      .then(data => {
        const {token, tipo_acesso} = data.data
        const payload = jwtDecode(token)

        LocalStorage.save('token', token)
        LocalStorage.save('tipo_acesso', tipo_acesso)
        LocalStorage.save('usuario', JSON.stringify(payload.usuario))
        swal('Login efetuado com sucesso.')
        if(tipo_acesso === "conselheiro"){
          history.push("/notificacao")
        }else{
          history.push("/")
        }
      })
      .catch(err => {
        console.log(err)
        swal('Dados incorretos.')
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuário"
            name="usuario"
            autoComplete="usuario"
            autoFocus
            value={dados.usuario}
            onChange={handleChangeText}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
            value={dados.senha}
            onChange={handleChangeText}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Esqueci minha senha
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      { 'GE-SIVID' }
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}