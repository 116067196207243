import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { TextField, Box, Grid, Button, MenuItem, Modal } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import axios from '../../Services/axios'
import Swal from 'sweetalert2'

export default function CadastrarConselheiro(props){

    const { conselho_id, id } = useParams()
    const [conselhos, setConselhos] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const classes = style()

    useEffect(() => {
        axios
            .get("conselhoTutelar")
            .then(({data}) => {
                setConselhos(data.data)
                
            })
    }, [])

    useEffect(() => {
        axios
            .get(`conselheiro/${id}`)
            .then(({data}) => {
                setDados((data.data)[0])
                console.log(data)
            })
    }, [id])

    const [dados, setDados] = useState({
        conselho_id: conselho_id || 0,
        cpf: '',
        nome: '',
        nascimento: '',
        inicioMandato: '',
        fimMandato: '',
        telefone: '',
        email: '',
        rua: '',
        numero: '',
        bairro: '',
        estado: '',
        cidade: '',
    })

    const validations = {
        conselho_id: 'number|required',
        cpf: 'string|required',
        nome: 'string|required',
        nascimento: 'string|required',
        inicioMandato: 'string|required',
        fimMandato: 'string|required',
        telefone: 'string|required',
        email: 'string|required',
        rua: 'string|required',
        numero: 'number|required',
        bairro: 'string|required',
        estado: 'string|required',
        cidade: 'string|required'
    }
    
    const INITIAL_ERRORS = {
        conselho_id: 0,
        cpf: false,
        nome: false,
        nascimento: false,
        inicioMandato: false,
        fimMandato: false,
        telefone: false,
        email: false,
        rua: false,
        numero: false,
        bairro: false,
        estado: false,
        cidade: false
    }
    const [errors, setErrors] = useState(INITIAL_ERRORS)

    const openAcceptTerms = () => {
        validate(dados)
        setOpenModal(true)
    }

    const save = () => {

        const data = dados

        const nascimento = data.nascimento.split("/")
        data.nascimento = `${nascimento[1]}/${nascimento[0]}/${nascimento[2]}`

        const inicioMandato = data.inicioMandato.split("/")
        data.inicioMandato = `${inicioMandato[1]}/${inicioMandato[0]}/${inicioMandato[2]}`

        const fimMandato = data.fimMandato.split("/")
        data.fimMandato = `${fimMandato[1]}/${fimMandato[0]}/${fimMandato[2]}`

        if(id){
            axios
                .put("conselheiro/" + id, data)
                .then(({data}) => {
                    Swal.fire("Registro alterado com sucesso.")
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire("Falha ao savar. Verifique os dados e tente novamente.")
                })
        }else{
            axios
                .post("conselheiro", data)
                .then(({data}) => {
                    Swal.fire("Registro incluído com sucesso. A nova senha é: " + data.senha)
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire("Falha ao savar. Verifique os dados e tente novamente.")
                })
        }

        setOpenModal(false)
    }

    const validate = (dados) => {

        let localErrors = INITIAL_ERRORS

        const keys = Object.keys(validations)

        keys.forEach(key => {
            const constraints = validations[key].split('|')
            constraints.forEach(constraint => {
                const dado = dados[key]
                switch(constraint){
                    case 'string':
                        if(typeof dado !== 'string'){
                            localErrors[key] = key
                        }
                    break;

                    case 'number':
                        if(typeof dado !== 'number'){
                            localErrors[key] = key
                        }
                    break;
                    
                    case 'required':
                        if(dado === '' || dado === 0){
                            localErrors[key] = key
                        }
                    break;

                    default:
                        break;
                }
            })
        })

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Conselheiro Tutelar</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <div className={classes.paper}>
                    <h2 className={classes.titleModal}>Termo de confidencialidade</h2>
                    <div className={classes.termo}>
                        <p>Fico obrigado a manter em ABSOLUTO SIGILO toda e qualquer informação que tiver acesso em função das minhas atribuições profissionais referentes aos Sistema de Informação de Violação de Direitos - GE-SIVID, entendendo como confidencial e sigilosa toda informação relativa aos instrumentos de coleta, contendo dados de identificação das crianças, adolescentes e suas famílias. Para tanto, concordo e comprometo-me à:</p>
                        
                        <p>1. Manter sigilo escrito e verbal de todos os dados, informações técnicas e administrativas obtidas ou não com minha participação, zelando pela privacidade dos indivíduos relacionados/listados nesta(s) base(s) de dados;</p>
                        
                        <p>2. Não divulgar, publicar e publicizar quaisquer dados ou informações que tenha tomado conhecimento ou elaborado no decorrer de minhas atividades sem prévia autorização institucional.</p>
                        
                        <p>3. Utilizar os dados de acesso restrito mantendo a necessária cautela quando da exibição de dados em tela, impressora ou gravação em meios eletrônicos, a fim de evitar que deles venham tomar ciência, pessoas não autorizadas.</p>
                        
                        <p>4. Não fazer cópias, registros escritos ou em mídias eletrônicas de quaisquer dados ou informações que não sejam necessários, por força de minhas atribuições e responsabilidades, assim como tomar precauções e as devidas medidas de segurança, para que no âmbito de minhas atribuições e responsabilidades, tais dados e informações não sejam copiados, revelados, ou venham a ser usados indevidamente ou sem autorização.</p>
                        
                        <p>5. Não praticar qualquer medida fora de minhas atribuições com a finalidade de obter para mim ou terceiros, direitos, vantagens pessoais ou financeiras relativas às informações que tenho acesso.</p>
                        
                        <p>6. Não ausentar-me da estação de trabalho sem encerrar a sessão de uso dos sistemas, garantindo assim a impossibilidade de acesso indevido de terceiros.</p>
                        
                        <p>7. Não revelar minha senha de acesso ao(s) sistema(s) a outros, cuidando para que a mesma seja somente de meu conhecimento.</p>
                        
                        <p>8. Alterar minha senha de acesso quando da possibilidade de violação ou conhecimento de terceiros e, não utilizar combinações óbvias ou de fácil acesso à mesma.</p>
                        
                        <p>9. Notificar imediatamente ao responsável técnico pelos referidos sistemas quando do eventual desligamento das funções ou atribuições referentes a tais sistemas.</p>
                        
                        <p>10. Observar e cumprir as boas práticas de segurança da informação e das diretrizes preconizadas por tais sistemas de informações.</p>
                        
                        <p>Declaro ainda estar ciente de que o não cumprimento de tais normas caracterizará infração ética podendo acarretar punição nas esferas penal, civil e administrativa de acordo com a legislação vigente. </p>
                    </div>
                    <Grid container spacing={1} justify="flex-end">
                        <Grid item xs={12}>
                            <br />
                            <Button variant="contained" color="primary" onClick={save}>
                                Aceitar
                            </Button>&nbsp;
                            <Button variant="contained" color="default">
                                Recusar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Conselho Tutelar" variant="outlined" size="small" error={errors.conselho_id ? true : false} value={dados.conselho_id} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    conselho_id: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    conselhos.map(conselho => (
                                        <MenuItem value={conselho.id} key={conselho.id}>{conselho.nome} - {conselho.cidade}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CPF" size="small" fullWidth error={errors.cpf ? true : false} value={dados.cpf} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cpf: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Data de nascimento"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            nascimento: value
                                        })
                                    }}
                                    error={errors.nascimento ? true : false}
                                    inputValue={dados.nascimento}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Início do Mandato"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            inicioMandato: value
                                        })
                                    }}
                                    error={errors.inicioMandato ? true : false}
                                    inputValue={dados.inicioMandato}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Fim do Mandato"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            fimMandato: value
                                        })
                                    }}
                                    error={errors.fimMandato ? true : false}
                                    inputValue={dados.fimMandato}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>
                
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} value={dados.cidade} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Rua" size="small" fullWidth error={errors.rua ? true : false} value={dados.rua} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    rua: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Número" size="small" fullWidth error={errors.numero ? true : false} value={dados.numero} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    numero: parseInt(event.target.value)
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Bairro" size="small" fullWidth error={errors.bairro ? true : false} value={dados.bairro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    bairro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone" size="small" fullWidth error={errors.telefone ? true : false} value={dados.telefone} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="E-mail" size="small" fullWidth error={errors.email ? true : false} value={dados.email} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    email: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => openAcceptTerms()}>
                          Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}