import React from 'react'
import clsx from 'clsx'

import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MenuIcon from '@material-ui/icons/Menu'

import actions from '../../Redux/Actions/Sidebar'
import { connect } from 'react-redux'

import Sidebar from '../Sidebar'

import useStyles from './styles'
import { useHistory } from 'react-router-dom'

import LocalStorage from '../../Services/LocalStorage'

import GestorMunicipal from '../../Pages/GestorMunicipal'
import CadastrarGestorMunicipal from '../../Pages/GestorMunicipal/CadastrarGestorMunicipal'

import InstituicaoEducacional from '../../Pages/InstituicaoEducacional'
import CadastrarInstituicaoEducacional from '../../Pages/InstituicaoEducacional/CadastrarInstituicaoEducacional'

import Diretor from '../../Pages/Diretor'
import CadastrarDiretor from '../../Pages/Diretor/CadastrarDiretor'

import ConselhoTutelar from '../../Pages/ConselhoTutelar'
import CadastrarConselho from '../../Pages/ConselhoTutelar/CadastrarConselho'

import Conselheiro from '../../Pages/Conselheiro'
import CadastrarConselheiro from '../../Pages/Conselheiro/CadastrarConselheiro'

import Usuarios from '../../Pages/Usuarios'

import Aluno from '../../Pages/Aluno'
import CadastrarAluno from '../../Pages/Aluno/CadastrarAluno'

import CadastrarNotificacao from '../../Pages/Notificacao/CadastrarNotificacao'
import VisualizarNotificacao from '../../Pages/Notificacao/VisualizarNotificacao'
import Notificacao from '../../Pages/Notificacao'

import Feedback from '../../Pages/Feedback'

import PrivateRoute from '../PrivateRoute'
import CadastrarFeedback from '../../Pages/Feedback/CadastrarFeedback'
import Estatisticas from '../../Pages/Estatisticas'
import Historico from '../../Pages/Aluno/Historico'

function App({ open, dispatch }){
    const classes = useStyles()
    const history = useHistory()

    const usuario = JSON.parse(LocalStorage.get("usuario"))

    const handleDrawerOpen = () => {
        dispatch(actions.toggleSidebar())
    }
    
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

    function handleLogoff(){
        LocalStorage.clear()
        history.push("/login")
    }

    const tipo_acesso_logado = LocalStorage.get("tipo_acesso")

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="p" color="inherit" noWrap className={classes.title}>
                        Olá {usuario.nome}!
                    </Typography>
                    <Typography component="p" color="inherit" noWrap onClick={handleLogoff}>
                        Sair
                    </Typography>
                </Toolbar>
            </AppBar>
            <Sidebar openSidebar={open} tipo_acesso={tipo_acesso_logado} />
            <main className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={fixedHeightPaper}>
                                <PrivateRoute
                                    path="/gestorMunicipal"
                                    exact
                                    tipo_acesso="gestor"
                                >
                                    <GestorMunicipal />
                                </PrivateRoute>

                                <PrivateRoute
                                    path="/gestorMunicipal/cadastrar"
                                    exact
                                    tipo_acesso="gestor"
                                >
                                    <CadastrarGestorMunicipal />
                                </PrivateRoute>

                                <PrivateRoute
                                    path="/gestorMunicipal/:id/editar"
                                    exact
                                    tipo_acesso="gestor"
                                >
                                    <CadastrarGestorMunicipal />
                                </PrivateRoute>

                                <PrivateRoute 
                                    tipo_acesso="gestor"
                                    path="/instituicaoEducacional" 
                                    exact
                                >
                                    <InstituicaoEducacional />
                                </PrivateRoute>

                                <PrivateRoute 
                                    tipo_acesso="gestor"
                                    path="/instituicaoEducacional/cadastrar" 
                                    exact
                                >
                                    <CadastrarInstituicaoEducacional />
                                </PrivateRoute>

                                <PrivateRoute 
                                    tipo_acesso="gestor"
                                    path="/instituicaoEducacional/:id/editar" 
                                    exact
                                >
                                    <CadastrarInstituicaoEducacional />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/instituicaoEducacional/:instituicao_id/cadastrarDiretor" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarDiretor />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/diretor" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <Diretor />
                                </PrivateRoute>
                                
                                <PrivateRoute 
                                    path="/diretor/cadastrar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarDiretor />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/diretor/:id/editar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarDiretor />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/conselho" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <ConselhoTutelar />
                                </PrivateRoute>
                                
                                <PrivateRoute 
                                    path="/conselho/cadastrar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarConselho />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/conselho/:id/editar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarConselho />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/conselheiro" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <Conselheiro />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/conselheiro/cadastrar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarConselheiro />
                                </PrivateRoute>

                                <PrivateRoute 
                                    path="/conselheiro/:id/editar" 
                                    exact
                                    tipo_acesso="gestor"    
                                >
                                    <CadastrarConselheiro />
                                </PrivateRoute>

                                <PrivateRoute path="/usuarios" exact>
                                    <Usuarios />
                                </PrivateRoute>

                                <PrivateRoute path="/alunos" exact>
                                    <Aluno />
                                </PrivateRoute>

                                <PrivateRoute path="/alunos/:id/historico" exact>
                                    <Historico />
                                </PrivateRoute>

                                <PrivateRoute path="/alunos/cadastrar" exact>
                                    <CadastrarAluno />
                                </PrivateRoute>

                                <PrivateRoute path="/alunos/:id/editar" exact>
                                    <CadastrarAluno />
                                </PrivateRoute>

                                <PrivateRoute path="/notificacao" exact>
                                    <Notificacao />
                                </PrivateRoute>
                                
                                <PrivateRoute path="/notificacao/cadastrar" exact>
                                    <CadastrarNotificacao />
                                </PrivateRoute>

                                <PrivateRoute path="/notificacao/:notificacao_id/feedback" exact>
                                    <CadastrarFeedback />
                                </PrivateRoute>

                                <PrivateRoute path="/feedback" exact>
                                    <Feedback />
                                </PrivateRoute>

                                <PrivateRoute path="/notificacao/:id/visualizar" exact>
                                    <VisualizarNotificacao />
                                </PrivateRoute>

                                <PrivateRoute path="/estatisticas" exact>
                                    <Estatisticas />
                                </PrivateRoute>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}

export default connect(state => {
    return (
        {
            open: state.Sidebar.open
        }
    )
})(App)