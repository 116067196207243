import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import style from './style'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Block from '@material-ui/icons/Block'
import { Lock } from '@material-ui/icons'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { Button, TextField, Tooltip } from '@material-ui/core'
import swal from 'sweetalert'

export default function Usuarios(){
    const classes = style()
    const [usuarios, setUsuarios] = useState([])
    const tipo_acesso = localStorage.getItem("tipo_acesso")
    const history = useHistory()

    useEffect(() => {
        axios
            .get("usuarios")
            .then(({data}) => setUsuarios(data))
            .catch(err => Swal.fire("Falha ao carregar lista de usuários. Recarregue a página."))
    }, [])

    const [open, setOpen] = React.useState(false)

    const [selectedUser, setSelectedUser] = useState({})

    const toggleDialog = () => {
        setOpen(!open);
    }

    const save = () => {
        // alert(password)
        console.log(selectedUser)

        let user = selectedUser.user

        user.senha = password

        axios.put(`${selectedUser.tipo_acesso}/${selectedUser.user.id}`, user)
            .then(data => {
                swal("Senha alterada com sucesso.")
                toggleDialog()
            })
            .catch(data => {
                swal("Falha inesperada ao salvar senha")
            })
    }

    const [password, setPassword] = useState("")

    const handleTextChange = (evt) => {
        setPassword(evt.target.value)
    }

    return (
        <div>
            <Dialog open={open} onClose={toggleDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Alterar Senha</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Digite abaixo a nova senha para o usuário
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="senha"
                        label="Nova Senha"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={handleTextChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={save} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
            <Typography className={classes.title} variant="h1" >Usuários Cadastrados no Sistema</Typography>

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Matrícula</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">CPF</TableCell>
                            <TableCell align="center">Tipo</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(usuarios).map(key => {
                                return (usuarios[key]).map((row) => (
                                    <TableRow>
                                        <TableCell component="td" scope="row">
                                            {row.matricula}
                                        </TableCell>
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell>{row.cpf}</TableCell>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>
                                            {
                                                tipo_acesso === "gestor" && (
                                                    <>
                                                        <IconButton aria-label="" onClick={() => {
                                                            Swal.fire({
                                                                title: 'Tem certeza que deseja excluir?',
                                                                text: "Você não poderá desfazer esta ação!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Sim!'
                                                            }).then((result) => {
                                                                if (result.value) {
                                                                    axios
                                                                        .delete(`${key}/${row.id}`)
                                                                        .then(() => {
                                                                            const newData = (usuarios[key]).filter(usr => usr.id !== row.id)
                                                                            setUsuarios({
                                                                                ...usuarios,
                                                                                [key] : newData
                                                                            })
                                                                            Swal.fire(
                                                                            'Excluído!',
                                                                            'Registro excluído com sucesso..',
                                                                            'success'
                                                                            )
                                                                        })
                                                                        .catch(err => {
                                                                            console.log(err)
                                                                            Swal.fire("Falha ao excluir. Tente novamente.")
                                                                        })
                                                                }
                                                            })
                                                        }}>
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                        <IconButton aria-label="" onClick={() => {
                                                            if(key === "gestor"){
                                                                key = "gestorMunicipal"
                                                            }
                                                            history.push(`/${key}/${row.id}/editar`)
                                                        }}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                        <IconButton aria-label="" onClick={() => {
                                                            alert("Bloquear")
                                                        }}>
                                                            <Block color="secondary" />
                                                        </IconButton>
                                                        <Tooltip title="Alterar Senha">
                                                            <IconButton onClick={() => {
                                                                setSelectedUser({
                                                                    tipo_acesso: key,
                                                                    user: row
                                                                })
                                                                toggleDialog()
                                                            }}>
                                                                <Lock color="secondary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                )) 
                            })
                            
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}