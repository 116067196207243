import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import style from './style'

import { TextField, Box, Grid, Button, Modal, MenuItem, Tooltip } from '@material-ui/core'

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import axios from '../../Services/axios'

import SelectBox from '../../Components/SelectBox'
import MultiSelectBox from '../../Components/MultiSelectBox'
import Swal from 'sweetalert2'

export default function CadastrarNotificacao(props){

    const classes = style()
    const [errors] = useState({})

    const [dados, setDados] = useState({
        dataIdentificacao: '',
        aluno_id: 0,
        origem_id: 0,
        tipo_id: 0,
        tipologia_id: 0,
        subTipologia_id: 0,
        outroTipo_id: 0,
        provavelAutor_id: 0,
        outroProvavelAutor: '',
        nomeProvavelAutor: '',
        localViolencia: '',
        faixaEtariaAutor_id: 0,
        provavelAutor: 0,
        faixaEtariaAutor: 0,
        vulnerabilidadesFamilia: [],
        sinaisComportamentais: [],
        sinaisAprendizagem: [],
        sinaisFisicos: [],
        sinaisRelacionamento: [],
        acoesInstituicaoEducacional: [],
        orgaos: [],
        servicos: [],
        atitudesNorteadoras: [],
        comentarios: '',
        tiposViolencia: [],
        outraViolencia: '',
        outroSinalComportamental: '',
        outroSinalAprendizagem: '',
        outroSinalFisico: '',
        outroSinalRelacionamento: '',
        outroVulnerabilidade: '',
        outrasAcoes: '',
        outraAtitudeNorteadora: '',
        outroServicoEncaminhado: '',
        outroOrgaoInserido: '',
        outraOrigem: ''
    })

    const [alunoList, setAlunoList]                                             = useState([])
    const [origemList, setOrigemList]                                           = useState([])
    const [tipoViolenciaList, setTipoViolenciaList]                             = useState([])
    const [tipologiaList, setTipologiaList]                                     = useState([])
    const [subTipologiaList, setSubTipologiaList]                               = useState([])
    const [showSearchModal, setShowSearchModal]                                 = useState(false)
    const [provavelAutorList, setProvavelAutorList]                             = useState([])
    const [faixaEtariaAutorList, setFaixaEtariaAutorList]                       = useState([])
    const [vulnerabilidadesList, setVulnerabilidadesList]                       = useState([])
    const [sinaisComportamentaisList, setSinaisComportamentaisList]             = useState([])
    const [sinaisAprendizagemList, setSinaisAprendizagemList]                   = useState([])
    const [sinaisFisicosList, setSinaisFisicosList]                             = useState([])
    const [sinaisRelacionamentoList, setSinaisRelacionamentoList]               = useState([])
    const [acoesInstituicaoEducacionalList, setAcoesInstituicaoEducacionalList] = useState([])
    const [orgaosList, setOrgaosList]                                           = useState([])
    const [servicosList, setServicosList]                                       = useState([])
    const [atitudesNorteadorasList, setAtitudesNorteadorasList]                 = useState([])
    const [nomeAluno, setNomeAluno]                                             = useState("")

    useEffect(() => {
        axios.get("aluno")
             .then(({data}) => setAlunoList(data.data))
             .catch(err => console.log("Não carregou"))

        axios.get("origemViolencia")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                })
                setOrigemList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("tipoViolencia")
             .then(({data}) => {
                 const list = data.data.map(item => {
                     return {
                         ...item,
                         label: item.descricao
                     }
                 })
                 setTipoViolenciaList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("tipologiaViolencia")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setTipologiaList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("subTipologiaViolencia")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setSubTipologiaList(list)
             })
             .catch(err => console.log("Não carregou"))
        
        axios.get("provavelAutor")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setProvavelAutorList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("faixaEtariaAutor")
            .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setFaixaEtariaAutorList(list)
            })
            .catch(err => console.log("Não carregou"))

        axios.get("acoesInstituicaoEducacional")
            .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setAcoesInstituicaoEducacionalList(list)
            })
            .catch(err => console.log("Não carregou"))

        axios.get("atitudesNorteadoras")
            .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setAtitudesNorteadorasList(list)
            })
            .catch(err => console.log("Não carregou"))

        axios.get("servicos")
            .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setServicosList(list)
            })
            .catch(err => console.log("Não carregou"))

        axios.get("orgaos")
            .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                }) 
                setOrgaosList(list)
            })
            .catch(err => console.log("Não carregou"))
             
        axios.get("vulnerabilidadesFamilia")
             .then(({data}) => {
                 const list = data.data.map(item => {
                     return {
                         ...item,
                         label: item.descricao
                     }
                 })
                 setVulnerabilidadesList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("sinaisComportamentais")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                })
                setSinaisComportamentaisList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("sinaisFisicos")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                })
                setSinaisFisicosList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("sinaisAprendizagem")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                })
                setSinaisAprendizagemList(list)
             })
             .catch(err => console.log("Não carregou"))

        axios.get("sinaisRelacionamento")
             .then(({data}) => {
                const list = data.data.map(item => {
                    return {
                        ...item,
                        label: item.descricao
                    }
                })
                setSinaisRelacionamentoList(list)
             })
             .catch(err => console.log("Não carregou"))
    }, [])

    useEffect(() => {
        if(dados.dataIdentificacao === ""){
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
    
            today = dd + '/' + mm + '/' + yyyy;
            
            setDados({
                ...dados,
                dataIdentificacao: today
            })
        }
    }, [dados])

    const handleMultiSelect = (value, name) => {
        const field = name
        let data = dados[field]

        if(data.includes(value)){
            data = data.filter(item => parseInt(item) !== parseInt(value))
        }else{
            data.push(parseInt(value))
        }

        setDados({
            ...dados,
            [field]: data
        })
    }

    const handleMultiSelectTipoViolencia = (value, name) => {
        const field = name
        let data = dados[field]

        if(data.map(item => item.id).includes(value.id)){
            data = data.filter(item => item.id !== value.id)
        }else{
            data.push(value)
        }

        setDados({
            ...dados,
            [field]: data
        })
    }

    const toggleSearchModal = () => setShowSearchModal(!showSearchModal)

    const [showTipologiaModal, setShowTipologiaModal] = useState(false)
    const toggleTipologiaModal = () => setShowTipologiaModal(!showTipologiaModal)

    const handleChangeValue = (evt) => {
        const field = evt.target.name
        const value = evt.target.value

        setDados({
            ...dados,
            [field]: value
        })
    }

    const save = () => {
        const data = dados
        const date = dados.dataIdentificacao.split("/")
        const identificationDate = `${date[2]}-${date[1]}-${date[0]}`

        data.dataIdentificacao = identificationDate

        axios
            .post("/notificacao", data)
            .then(data => Swal.fire("Salvo com sucesso."))
            .catch(err => {
                Swal.fire("Ocorreu um erro ao salvar os dados. Por favor tente novamente.")
                console.log(err)
            })
    }

    const handleChangeSearchValue = (evt) => {
        setNomeAluno(evt.target.value)
    }

    const searchStudent = () => {
        if(!nomeAluno){
            alert("Digite um nome para pesquisar.")
        }else{
            axios.get(`aluno?nome=${nomeAluno}`)
             .then(({data}) => setAlunoList(data.data))
             .catch(err => console.log("Não carregou"))
        }


    }

    const handleSelect = (evt) => {
        console.log(evt)
        setSelected(parseInt(evt.target.value))
    }

    const [selected, setSelected] = useState(0)

    // console.log(dados.tiposViolencia)

    const addTipologia = (tipo_id, tipologia_id, subTipologia_id) => {
        // console.log(dados.tiposViolencia, tipo_id, tipologia_id, subTipologia_id)
        let tipoViolencia = dados.tiposViolencia.filter(item => item.id === tipo_id)[0]
        // console.log(tipoViolencia)
        //id, tipologias
        //verifica se existe tipologia
        if(!tipoViolencia.tipologias){
            tipoViolencia.tipologias = []
        }
        let tipologia = tipoViolencia.tipologias.filter(item => item.id === tipologia_id)[0]
        if(tipologia){
            if(tipologia.subTipologias){
                tipologia.subTipologias.push(subTipologia_id)
            }else{
                tipologia.subTipologias = [subTipologia_id]
            }

            let tipologias = tipoViolencia.tipologias.filter(item => item.id !== tipologia_id)
            tipologias.push(tipologia)

            tipoViolencia.tipologias = tipologias
        }else{
            let newTipologia = {
                id: tipologia_id,
                subTipologias: [ subTipologia_id ]
            }

            tipoViolencia.tipologias.push(newTipologia)
        }

        let tiposViolencia = dados.tiposViolencia.filter(item => item.id !== tipo_id)
        tiposViolencia.push(tipoViolencia)

        //se existe add subtipologia
        //se não add tipologia + subtipologia
        // console.log(tiposViolencia)
        setDados({
            ...dados,
            tiposViolencia
        })
        toggleTipologiaModal()
    }

    const removeTipologia = (tipo_id, tipologia_id) => {
        let tiposViolencia = dados.tiposViolencia

        let tipoViolencia = tiposViolencia.filter(item => item.id === tipo_id)[0]

        let newTipologias = tipoViolencia.tipologias.filter(item => item.id !== tipologia_id)

        tipoViolencia.tipologias = newTipologias

        let newTiposViolencia = tiposViolencia.filter(item => item.id !== tipo_id)
        newTiposViolencia.push(tipoViolencia)

        setDados({
            ...dados,
            tiposViolencia: newTiposViolencia
        })
    }

    const removeSubTipologia = (tipo_id, tipologia_id, subTipologia_id) => {
        let tiposViolencia = dados.tiposViolencia

        let tipoViolencia = tiposViolencia.filter(item => item.id === tipo_id)[0]

        let tipologia = tipoViolencia.tipologias.filter(item => item.id === tipologia_id)[0]

        let newSubTipologias = tipologia.subTipologias.filter(item => item !== subTipologia_id)

        tipologia.subTipologias = newSubTipologias

        let newTipologias = tipoViolencia.tipologias.filter(item => item.id !== tipologia_id)
        newTipologias.push(tipologia)

        tipoViolencia.tipologias = newTipologias

        let newTiposViolencia = tiposViolencia.filter(item => item.id !== tipo_id)
        newTiposViolencia.push(tipoViolencia)

        setDados({
            ...dados,
            tiposViolencia: newTiposViolencia
        })
    }

    return (
        <>
            <Typography className={classes.title} variant="h1" >Cadastro de Ficha de Notificação e Identificação</Typography>

            <Modal
                open={showSearchModal}
                onClose={() => {
                    toggleSearchModal()
                }}
            >
                <div className={classes.paper}>
                    <h2 className={classes.titleModal}>Alunos</h2>
                    <div className={classes.termo}>
                        <div>
                            <form>
                                <Grid container spacing={1}>
                                    <Grid item xs={8} sm={6}>
                                        <Box component="div" m={4}>
                                            <TextField 
                                                fullWidth  
                                                label="Nome do aluno" 
                                                variant="outlined" 
                                                size="small" 
                                                value={nomeAluno} 
                                                name="nomeAluno"
                                                onChange={handleChangeSearchValue} 
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={6}>
                                        <Box component="div" m={4}>
                                            <Button variant="contained" color="primary" onClick={searchStudent}>
                                                Pesquisar
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>CPF</TableCell>
                                        <TableCell align="center">Nome</TableCell>
                                        <TableCell align="center">Data Nasc.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        alunoList.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="td" scope="row">
                                                    <Button variant="outlined" color="primary" onClick={
                                                        () => {
                                                            setDados({
                                                                ...dados,
                                                                aluno_id: row.id
                                                            })
    
                                                            toggleSearchModal()
                                                        }
                                                    }>Selecionar</Button>
                                                </TableCell>
                                                <TableCell>
                                                    {row.cpf}
                                                </TableCell>
                                                <TableCell>{row.nome}</TableCell>
                                                <TableCell>{row.nascimento}</TableCell>
                                            </TableRow>
                                        )) 
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Modal>

            <Modal
                open={showTipologiaModal}
                onClose={() => {
                    toggleTipologiaModal()
                }}
            >
                <div className={classes.paper}>
                    <h2 className={classes.titleModal}>Selecione a Tipologia e a SubTipologia se houver e clique em Adicionar</h2>
                    <div>
                        <div>
                            <form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <SelectBox 
                                            label={"Tipologia de Violência"}
                                            error={false}
                                            onChange={handleChangeValue}
                                            name={"tipologia_id"}
                                            value={dados.tipologia_id}
                                            items={
                                                tipologiaList
                                                    .filter(tipologia => parseInt(tipologia.tipo_id) === parseInt(dados.tipo_id))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    dados.tipologia_id !== 0 ?
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <SelectBox 
                                                    label={"Subtipologia de Violência"}
                                                    error={false}
                                                    onChange={handleChangeValue}
                                                    name={"subTipologia_id"}
                                                    value={dados.subTipologia_id}
                                                    items={
                                                        subTipologiaList
                                                            .filter(subtipologia => parseInt(subtipologia.tipologia_id) === parseInt(dados.tipologia_id))
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    : null
                                }

                                <Grid container spacing={1}>    
                                    <Grid item xs={12} className={classes.textCenter}>
                                        <Button variant="contained" color="primary" onClick={() => addTipologia(dados.tipo_id, dados.tipologia_id, dados.subTipologia_id)}>Adicionar</Button>
                                        <Button variant="contained" color="secondary" onClick={() => toggleTipologiaModal()}>Cancelar</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Data da identificação"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            dataIdentificacao: value
                                        })
                                    }}
                                    error={errors.dataIdentificacao ? true : false}
                                    inputValue={dados.dataIdentificacao}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={8} sm={6}>
                        <Box component="div" m={4}>
                            <TextField 
                                fullWidth  
                                label="Aluno" 
                                variant="outlined" 
                                size="small" 
                                error={errors.aluno_id ? true : false} 
                                value={dados.aluno_id} 
                                name="aluno_id"
                                disabled
                                onChange={handleChangeValue} 
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={4} sm={6}>
                        <Box component="div" m={4}>
                            <Button variant="contained" color="primary" onClick={toggleSearchModal}>
                                Pesquisar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <SelectBox 
                            label={"Origem da Notificação"}
                            error={false}
                            onChange={handleChangeValue}
                            name={"origem_id"}
                            value={dados.origem_id}
                            items={origemList}
                        />
                        {
                            [6, 8, 11].includes(dados.origem_id) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Descreva" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outraOrigem ? true : false} 
                                            value={dados.outraOrigem} 
                                            name="outraOrigem"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <div className={classes.tipoViolenciaContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <Box component="div" m={4}>
                                <TextField 
                                    variant="outlined" 
                                    select
                                    label="Tipo de Violência"
                                    size="small" 
                                    fullWidth 
                                    value={selected} 
                                    onChange={handleSelect}
                                    name="tiposViolencia"
                                >
                                    
                                        <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                    
                                    {
                                        tipoViolenciaList.map(item => {
                                            if(item.tooltip != null){
                                                return (
                                                    <Tooltip key={item.id} value={item.id}  title={item.tooltip}>
                                                        <MenuItem value={item.id} >{item.label}</MenuItem>
                                                    </Tooltip>
                                                )
                                            }else{
                                                return (<MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>)
                                            }
                                        }
                                    
                                    )
                                            
                                    }
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box component="div" m={4}>
                                <Button variant="contained" color="default" onClick={() => handleMultiSelectTipoViolencia({id: selected}, "tiposViolencia")}>+</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <div className={classes.containerItems}>
                        <p className={classes.titleContainer}>Opções selecionadas</p>
                        {
                            dados.tiposViolencia.map(tipo => (
                                <div key={tipo.id} className={classes.selectedItem}>
                                    {
                                        tipoViolenciaList.map(t => {
                                            if(t.id === tipo.id){
                                                return (
                                                    <p key={t.id}>{t.label} - <Button onClick={() => handleMultiSelectTipoViolencia({id: t.id}, "tiposViolencia")} variant="text" color="secondary">Excluir</Button> <Button onClick={() => {
                                                        setDados({
                                                            ...dados,
                                                            tipo_id: t.id,
                                                            tipologia_id: 0,
                                                            subTipologia_id: 0
                                                        })
                                                        toggleTipologiaModal()
                                                    }} variant="text" color="primary">Adicionar Tipologia e SubTipologia</Button></p>
                                                )
                                            }else{
                                                return null
                                            }
                                        })
                                    }

                                    <div className={classes.tipologiasContainer}>
                                        {
                                            tipo.tipologias && tipo.tipologias.map(tipologia => {
                                                return tipologiaList.map(t => {
                                                    if(t.id === tipologia.id){
                                                        return (
                                                            <div key={t.id}>
                                                                <p >{ t.label } - <Button variant="text" color="secondary" onClick={() => removeTipologia(tipo.id, tipologia.id)}>Excluir</Button></p>
                                                                <div className={classes.subTipologiasContainer}>
                                                                    {
                                                                        tipologia.subTipologias && tipologia.subTipologias.map(subTipologia => {
                                                                            return subTipologiaList.map(s => {
                                                                                if(s.id === subTipologia){
                                                                                    return (
                                                                                        <p key={s.id}>{s.label} - <Button variant="text" color="secondary" onClick={() => removeSubTipologia(tipo.id, tipologia.id, s.id)}>Excluir</Button></p>
                                                                                    )
                                                                                }else{
                                                                                    return null
                                                                                }
                                                                            })
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }else{
                                                        return null
                                                    }
                                                })
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>

                    {
                        dados.tiposViolencia.map(tipo => tipo.id).includes(10) ? (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField 
                                        fullWidth  
                                        label="Outro Tipo de Violência" 
                                        variant="outlined" 
                                        size="small" 
                                        error={errors.outraViolencia ? true : false} 
                                        value={dados.outraViolencia} 
                                        name="outraViolencia"
                                        onChange={handleChangeValue} 
                                    />
                                </Grid>
                            </Grid>
                        ) : null
                    }
                </div>
                {/* <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <SelectBox 
                            label={"Tipologia de Violência"}
                            error={false}
                            onChange={handleChangeValue}
                            name={"tipologia_id"}
                            value={dados.tipologia_id}
                            items={
                                tipologiaList
                                    .filter(tipologia => parseInt(tipologia.tipo_id) === parseInt(dados.tipo_id))
                            }
                        />
                    </Grid>
                </Grid> */}

                <Typography className={classes.subtitle} variant="h4" >Identificação de Fatores</Typography>

                <p>Marque todos os sinais observados.</p>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Comportamentais"
                            addItem={handleMultiSelect}
                            name="sinaisComportamentais"
                            items={sinaisComportamentaisList}
                            selectedItems={
                                sinaisComportamentaisList.filter(sinal => dados.sinaisComportamentais.includes(sinal.id))
                            }
                        />
                        {
                            dados.sinaisComportamentais.includes(31) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Sinal Comportamental" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroSinalComportamental ? true : false} 
                                            value={dados.outroSinalComportamental} 
                                            name="outroSinalComportamental"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Aprendizagem"
                            addItem={handleMultiSelect}
                            name="sinaisAprendizagem"
                            items={sinaisAprendizagemList}
                            selectedItems={
                                sinaisAprendizagemList.filter(sinal => dados.sinaisAprendizagem.includes(sinal.id))
                            }
                        />
                        {
                            dados.sinaisAprendizagem.includes(8) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Sinal de Aprendizagem" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroSinalAprendizagem ? true : false} 
                                            value={dados.outroSinalAprendizagem} 
                                            name="outroSinalAprendizagem"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Físicos"
                            addItem={handleMultiSelect}
                            name="sinaisFisicos"
                            items={sinaisFisicosList}
                            selectedItems={
                                sinaisFisicosList.filter(sinal => dados.sinaisFisicos.includes(sinal.id))
                            }
                        />
                        {
                            dados.sinaisFisicos.includes(16) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Sinal Físico" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroSinalFisico ? true : false} 
                                            value={dados.outroSinalFisico} 
                                            name="outroSinalFisico"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Relacionamento"
                            addItem={handleMultiSelect}
                            name="sinaisRelacionamento"
                            items={sinaisRelacionamentoList}
                            selectedItems={
                                sinaisRelacionamentoList.filter(sinal => dados.sinaisRelacionamento.includes(sinal.id))
                            }
                        />
                        {
                            dados.sinaisRelacionamento.includes(5) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Sinal de Relacionamento" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroSinalRelacionamento ? true : false} 
                                            value={dados.outroSinalRelacionamento} 
                                            name="outroSinalRelacionamento"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <SelectBox 
                            label={"Provável autor"}
                            error={false}
                            onChange={handleChangeValue}
                            name={"provavelAutor_id"}
                            value={dados.provavelAutor_id}
                            items={provavelAutorList}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField 
                                fullWidth  
                                label="Outro Provável Autor (Preencher se assinalar outro)" 
                                variant="outlined" 
                                size="small" 
                                error={errors.outroProvavelAutor ? true : false} 
                                value={dados.outroProvavelAutor} 
                                name="outroProvavelAutor"
                                onChange={handleChangeValue} 
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <p>Deixar os campos abaixo em branco para ignorar.</p>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField 
                                fullWidth  
                                label="Local que ocorre a violência" 
                                variant="outlined" 
                                size="small" 
                                error={errors.localViolencia ? true : false} 
                                value={dados.localViolencia} 
                                name="localViolencia"
                                onChange={handleChangeValue} 
                                placeholder="Deixe em branco para ignorar"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField 
                                fullWidth 
                                label="Nome do Provável Autor" 
                                variant="outlined" 
                                size="small" 
                                error={errors.nomeProvavelAutor ? true : false} 
                                value={dados.nomeProvavelAutor} 
                                name="nomeProvavelAutor"
                                onChange={handleChangeValue} 
                                placeholder="Deixe em branco para ignorar"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <SelectBox 
                            label={"Faixa Etária do Provável autor"}
                            error={false}
                            onChange={handleChangeValue}
                            name={"faixaEtariaAutor_id"}
                            value={dados.faixaEtariaAutor_id}
                            items={faixaEtariaAutorList}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Vulnerabilidades no grupo familiar"
                            addItem={handleMultiSelect}
                            name="vulnerabilidadesFamilia"
                            items={ vulnerabilidadesList }
                            selectedItems={
                                vulnerabilidadesList.filter(sinal => dados.vulnerabilidadesFamilia.includes(sinal.id))
                            }
                        />
                        {
                            dados.vulnerabilidadesFamilia.includes(21) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outra Vulnerabilidade" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroVulnerabilidade ? true : false} 
                                            value={dados.outroVulnerabilidade} 
                                            name="outroVulnerabilidade"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Typography className={classes.subtitle} variant="h4" >Práticas Escolares</Typography>

                <p>Marque todas as opções pertinentes.</p>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Ações Executadas pela Instituição Educacional"
                            addItem={handleMultiSelect}
                            name="acoesInstituicaoEducacional"
                            items={ acoesInstituicaoEducacionalList }
                            selectedItems={
                                acoesInstituicaoEducacionalList.filter(sinal => dados.acoesInstituicaoEducacional.includes(sinal.id))
                            }
                        />
                        {
                            dados.acoesInstituicaoEducacional.includes(13) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outra Ação Executada" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outrasAcoes ? true : false} 
                                            value={dados.outrasAcoes} 
                                            name="outrasAcoes"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Atitudes norteadoras para a ação da instituição educacional"
                            addItem={handleMultiSelect}
                            name="atitudesNorteadoras"
                            items={ atitudesNorteadorasList }
                            selectedItems={
                                atitudesNorteadorasList.filter(sinal => dados.atitudesNorteadoras.includes(sinal.id))
                            }
                        />
                        {
                            dados.atitudesNorteadoras.includes(7) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outra Atitude Norteadora" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outraAtitudeNorteadora ? true : false} 
                                            value={dados.outraAtitudeNorteadora} 
                                            name="outraAtitudeNorteadora"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Serviços em que a criança ou adolescente já foi encaminhada, os quais são de conhecimento da instituição educacional"
                            addItem={handleMultiSelect}
                            name="servicos"
                            items={ servicosList }
                            selectedItems={
                                servicosList.filter(sinal => dados.servicos.includes(sinal.id))
                            }
                        />
                        {
                            dados.servicos.includes(17) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Serviço Encaminhado" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroServicoEncaminhado ? true : false} 
                                            value={dados.outroServicoEncaminhado} 
                                            name="outroServicoEncaminhado"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <MultiSelectBox
                            label="Órgãos de defesa que a criança ou adolescente está inserida"
                            addItem={handleMultiSelect}
                            name="orgaos"
                            items={ orgaosList }
                            selectedItems={
                                orgaosList.filter(sinal => dados.orgaos.includes(sinal.id))
                            }
                        />
                        {
                            dados.orgaos.includes(6) ? (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField 
                                            fullWidth  
                                            label="Outro Órgão'" 
                                            variant="outlined" 
                                            size="small" 
                                            error={errors.outroOrgaoInserido ? true : false} 
                                            value={dados.outroOrgaoInserido} 
                                            name="outroOrgaoInserido"
                                            onChange={handleChangeValue} 
                                        />
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" multiline={true} rows={10} label="Comentários adicionais" size="small" fullWidth error={errors.comentarios ? true : false} value={dados.comentarios} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    comentarios: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <Button variant="contained" color="primary" onClick={save}>
                                Próximo
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    )

}