import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import { Grid } from '@material-ui/core'

export default function(){
    const classes = style()
    const [dados, setDados] = useState({})

    useEffect(() => {
        axios
            .get("estatisticas")
            .then(({data}) => setDados(data))
            .catch(err => Swal.fire("Falha ao carregar lista de usuários. Recarregue a página."))
    }, [])

    console.log(dados)

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Dados registrados no sistema</Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.notificacoes }</strong> &nbsp;Notificações
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.conselheiros }</strong> &nbsp;Conselheiros
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.alunos }</strong> &nbsp;Alunos
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.feedbacks }</strong> &nbsp;Feedbacks
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.notificacoesPorAluno }</strong> &nbsp;Notificações por Aluno
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.tipoViolenciaMaisReferenciado }</strong> &nbsp;Tipo de Violência mais comum
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.item}>
                        <strong className={classes.counter}>{ dados.tipologiaMaisReferenciado }</strong> &nbsp;Tipologia mais comum
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}