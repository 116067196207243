import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { TextField, Box, Grid, Button } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'

export default function CadastrarConselho(){

    const { id } = useParams()
    const history = useHistory()
    const classes = style()
    const [dados, setDados] = useState({
        nome: '',
        estado: '',
        cidade: '',
    })

    useEffect(() => {
        axios
            .get(`conselhoTutelar/${id}`)
            .then(({data}) => {
                setDados((data.data)[0])
            })
            .catch(err => {
                console.log(err)
            })
    }, [id])

    const validations = {
        nome: 'string|required',
        estado: 'string|required',
        cidade: 'string|required'
    }
    
    const INITIAL_ERRORS = {
        nome: false,
        estado: false,
        cidade: false
    }
    const [errors, setErrors] = useState(INITIAL_ERRORS)

    const save = () => {
        validate(dados)

        if(id){
            axios
                .put(`conselhoTutelar/${id}`, dados)
                .then(() => {
                    Swal.fire("Registro salvo com sucesso.")
                    history.push("/conselho")
                })
                .catch(() => {
                    Swal.fire("Falha ao salvar registro, tente novamente.")
                })
        }else{
            axios
                .post(`conselhoTutelar`, dados)
                .then(() => {
                    Swal.fire("Registro salvo com sucesso.")
                    history.push("/conselho")
                })
                .catch(() => {
                    Swal.fire("Falha ao salvar registro, tente novamente.")
                })
        }
    }

    const validate = (dados) => {

        let localErrors = INITIAL_ERRORS

        const keys = Object.keys(validations)

        keys.forEach(key => {
            const constraints = validations[key].split('|')
            constraints.forEach(constraint => {
                const dado = dados[key]
                switch(constraint){
                    case 'string':
                        if(typeof dado !== 'string'){
                            localErrors[key] = key
                        }
                    break;

                    case 'number':
                        if(typeof dado !== 'number'){
                            localErrors[key] = key
                        }
                    break;
                    
                    case 'required':
                        if(dado === '' || dado === 0){
                            localErrors[key] = key
                        }
                    break;

                    default:
                        break;
                }
            })
        })

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Conselho Tutelar</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} value={dados.cidade} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => save()}>
                          Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}