import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import style from './style'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { ListAlt } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import { Tooltip } from '@material-ui/core'

export default function Aluno(){
    const classes = style()
    const [alunos, setAlunos] = useState([])
    const tipo_acesso = localStorage.getItem("tipo_acesso")

    useEffect(() => {
        axios
            .get("aluno")
            .then(({data}) => setAlunos(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar dados. Recarregue a página.")
            })
    }, [])

    const history = useHistory()
    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Alunos</Typography>

            {
                tipo_acesso === "diretor" && (
                    <div className={classes.containerButton}>
                        <Button variant="contained" color="primary" onClick={() => {
                            history.push("alunos/cadastrar")
                        }}>
                        Criar novo
                        </Button>
                    </div>
                )
            }

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>CPF</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Data Nasc.</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            alunos.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="td" scope="row">
                                        {row.cpf}
                                    </TableCell>
                                    <TableCell>{row.nome}</TableCell>
                                    <TableCell>{row.nascimento}</TableCell>
                                    <TableCell>
                                        {
                                            tipo_acesso === "diretor" && (
                                                <>
                                                    <IconButton aria-label="" onClick={() => {
                                                        Swal.fire({
                                                            title: 'Tem certeza que deseja excluir?',
                                                            text: "Você não poderá desfazer esta ação!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Sim!'
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                axios
                                                                    .delete(`aluno/${row.id}`)
                                                                    .then(() => {
                                                                        const newAlunos = alunos.filter(aluno => aluno.id !== row.id)
                                                                        setAlunos(newAlunos)
                                                                        Swal.fire(
                                                                        'Excluído!',
                                                                        'Registro excluído com sucesso..',
                                                                        'success'
                                                                        )
                                                                    })
                                                                    .catch(err => {
                                                                        console.log(err)
                                                                        Swal.fire("Falha ao excluir. Tente novamente.")
                                                                    })
                                                            }
                                                        })
                                                    }}>
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                    <IconButton aria-label="" onClick={() => {
                                                        history.push(`/alunos/${row.id}/editar`)
                                                    }}>
                                                        <EditIcon color="action" />
                                                    </IconButton>
                                                </>
                                            )
                                        }
                                        <Tooltip title="Histórico">
                                            <IconButton onClick={() => {
                                                history.push(`/alunos/${row.id}/historico`)
                                            }}>
                                                <ListAlt color="action"/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )) 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}