import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => (
    {
        title: {
            color: '#1E2E3A',
            fontSize: 28,
            fontWeight: 'bold',
            borderBottomColor: '#00B17C',
            borderBottomWidth: 3,
            borderBottomStyle: "solid"
        },
        containerButton: {
            margin: 10,
            textAlign: "right"
        },
        table: {
            width: '100%'
        },
        datePickerMargin: {
            marginLeft: 32
        },
        fullWidth: {
            width: '100%'
        }
    }
))