import React from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import style from './style'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { RemoveRedEye, ListRounded } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import axios from '../../Services/axios'
import { useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Tooltip } from '@material-ui/core'

export default function Feedback(){
    const classes = style()
    const [feedbacks, setFeedbacks] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const tipo_acesso = localStorage.getItem("tipo_acesso")
    const [viewerFeedback, setViewerFeedback] = useState(false)
    const [feedback, setFeedback] = useState("")
    
    useEffect(() => {
        axios
            .get("feedback")
            .then(({data}) => {
                setFeedbacks(data.data)
                setIsLoading(false)
            })
    }, [])

    const viewFeedback = (feed) => {
        setFeedback(feed)
        toggleFeedback()
    }

    const toggleFeedback = () => setViewerFeedback(!viewerFeedback)

    const history = useHistory()
    return (
        <div>
            <Modal
                open={viewerFeedback}
                onClose={toggleFeedback}
            >
                <div className={classes.paper}>
                    <h2 className={classes.titleModal}>Feedback</h2>
                    <div className={classes.termo}>
                        {feedback}
                    </div>
                </div>
            </Modal>
            <Typography className={classes.title} variant="h1" >Cadastro de Feedbacks</Typography>

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Cód. Notificação</TableCell>
                            <TableCell align="center">Aluno</TableCell>
                            <TableCell align="center">Conselheiro</TableCell>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading? (
                                <TableRow>
                                    <TableCell>Carregando...</TableCell>
                                </TableRow>
                            ): null
                        }
                        {
                            feedbacks.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.notificacao_id}</TableCell>
                                    <TableCell>{row.conselheiro.nome}</TableCell>
                                    <TableCell>{row.notificacao.aluno.nome}</TableCell>
                                    <TableCell>{row.created_at}</TableCell>
                                    <TableCell>
                                        {
                                            tipo_acesso === "conselheiro" && (
                                                <>
                                                    <IconButton aria-label="" onClick={() => {
                                                        Swal.fire({
                                                            title: 'Tem certeza que deseja excluir?',
                                                            text: "Você não poderá desfazer esta ação!",
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonColor: '#3085d6',
                                                            cancelButtonColor: '#d33',
                                                            confirmButtonText: 'Sim!'
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                axios
                                                                    .delete(`feedback/${row.id}`)
                                                                    .then(() => {
                                                                        const newFeedbacks = feedbacks.filter(feed => feed.id !== row.id)
                                                                        setFeedbacks(newFeedbacks)
                                                                        Swal.fire(
                                                                        'Excluído!',
                                                                        'Registro excluído com sucesso..',
                                                                        'success'
                                                                        )
                                                                    })
                                                                    .catch(err => {
                                                                        console.log(err)
                                                                        Swal.fire("Falha ao excluir. Tente novamente.")
                                                                    })
                                                            }
                                                        })
                                                    }}>
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                    <IconButton aria-label="" onClick={() => {
                                                        history.push(`/feedback/${row.id}/editar`)
                                                    }}>
                                                        <EditIcon color="action" />
                                                    </IconButton>
                                                </>
                                            )
                                        }
                                        <Tooltip title="Visualizar feedback">
                                            <IconButton onClick={() => viewFeedback(row.descricao)} >
                                                <RemoveRedEye />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Visualizar Notificação">
                                            <IconButton onClick={() => history.push(`notificacao/${row.notificacao_id}/visualizar`)} >
                                                <ListRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )) 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}