import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => (
    {
        title: {
            color: '#1E2E3A',
            fontSize: 28,
            fontWeight: 'bold',
            borderBottomColor: '#00B17C',
            borderBottomWidth: 3,
            borderBottomStyle: "solid",
            marginBottom: 50
        },
        item: {
            minHeight: 100,
            borderColor: '#CECECE',
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            marginBottom: 20,
            marginRight: 10,
            marginLeft: 10,
            textAlign: 'center',
            flexDirection: 'column',
            padding: 20
        },
        counter: {
            fontSize: 25
        }
    }
))