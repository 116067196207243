import React, { useState } from 'react'
import { Typography, TextField, MenuItem, Button, Grid, Box, Tooltip } from '@material-ui/core'

import styles from './style'

export default function({ label, name, addItem, items, selectedItems }){
    const classes = new styles()
    const [selected, setSelected] = useState(0)

    const handleSelect = (evt) => {
        console.log(evt)
        setSelected(parseInt(evt.target.value))
    }

    return (
        <div className={classes.formGroup}>
            <Typography variant="h6" >{ label }</Typography>

            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Box component="div" m={4}>
                        <TextField 
                            variant="outlined" 
                            select
                            label={label} 
                            size="small" 
                            fullWidth 
                            value={selected} 
                            onChange={handleSelect}
                            name={name}
                        >
                            <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                            {
                                items.map(item => {
                                    if(item.tooltip != null){
                                        return (
                                            <Tooltip key={item.id} value={item.id} title={item.tooltip}>
                                                <MenuItem value={item.id} >{item.label}</MenuItem>
                                            </Tooltip>
                                        )
                                    }else{
                                        return (
                                            <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                                        )
                                    }
                                })
                            }
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box component="div" m={4}>
                        <Button variant="contained" color="default" onClick={() => addItem(selected, name)}>+</Button>
                    </Box>
                </Grid>
            </Grid>

            <div className={classes.containerItems}>
                <p className={classes.titleContainer}>Opções selecionadas</p>
                {
                    selectedItems && selectedItems.map(item => (
                        <p key={item.id} className={classes.selectedItem}>{item.descricao} - <Button onClick={() => addItem(item.id, name)} variant="text" color="secondary">Excluir</Button></p>
                    ))
                }
            </div>
        </div>
    )
}