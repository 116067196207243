import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => (
    {
        title: {
            color: '#1E2E3A',
            fontSize: 28,
            fontWeight: 'bold',
            borderBottomColor: '#00B17C',
            borderBottomWidth: 3,
            borderBottomStyle: "solid"
        },
        containerButton: {
            margin: 10,
            textAlign: "right"
        },
        table: {
            width: '100%'
        },
        datePickerMargin: {
            marginLeft: 32
        },
        fullWidth: {
            width: '100%'
        },
        paper: {
            position: 'absolute',
            width: 600,
            height: 600,
            backgroundColor: 'white',
            padding: 10,
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            
        },
        titleModal: {
            textAlign: "center"
        },
        termo: {
            overflow: 'scroll',
            height: 400
        }
    }
))