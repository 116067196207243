import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { TextField, Box, Grid, Button, MenuItem } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../Services/axios'
import swal from 'sweetalert'

export default function GestorInstituicaoEducacional(){
    const classes = style()
    const history = useHistory()
    const { id } = useParams()

    const [tipoInstituicaoList, setTipoInstituicaoList] = useState([])

    const [dados, setDados] = useState({
        nome: '',
        inep: '',
        estado: '',
        cidade: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cep: '',
        telefone1: '',
        telefone2: '',
        telefone3: '',
        tipo_instituicao_id: 0
    })

    const [diretorDisabled, setDiretorDisabled] = useState(true)

    const validations = {
        nome: 'string|required',
        inep: 'string|required',
        estado: 'string|required',
        cidade: 'string|required',
        logradouro: 'string|required',
        numero: 'number|required',
        bairro: 'string|required',
        cep: 'string|required',
        telefone1: 'string|required',
        telefone2: 'string',
        telefone3: 'string',
        tipo_instituicao_id: 'number|required'
    }
    
    const INITIAL_ERRORS = {
        nome: false,
        inep: false,
        estado: false,
        cidade: false,
        logradouro: false,
        numero: false,
        bairro: false,
        cep: false,
        telefone1: false,
        telefone2: false,
        telefone3: false,
        tipo_instituicao_id: 0
    }
    const [errors, setErrors] = useState(INITIAL_ERRORS)

    const save = () => {
        validate(dados)
        setDiretorDisabled(false)
        console.log(dados)

        if(dados.id){
            axios
                .put(`/instituicao/${dados.id}`, dados)
                .then(data => {
                    console.log(data)
                    swal("Salvo com sucesso.")
                })
                .catch(err => {
                    console.log(err)
                    swal("Erro ao salvar.")
                })
        }else{
            axios
                .post("/instituicao", dados)
                .then(data => {
                    console.log(data)
                    swal("Salvo com sucesso.")
                })
                .catch(err => {
                    console.log(err)
                    swal("Erro ao salvar. Tente novamente.")
                })
        }

    }

    const validate = (dados) => {

        let localErrors = INITIAL_ERRORS

        const keys = Object.keys(validations)

        keys.forEach(key => {
            const constraints = validations[key].split('|')
            constraints.forEach(constraint => {
                const dado = dados[key]
                switch(constraint){
                    case 'string':
                        if(typeof dado !== 'string'){
                            localErrors[key] = key
                        }
                    break;

                    case 'number':
                        if(typeof dado !== 'number'){
                            localErrors[key] = key
                        }
                    break;
                    
                    case 'required':
                        if(dado === '' || dado === 0){
                            localErrors[key] = key
                        }
                    break;

                    default:
                        break;
                }
            })
        })

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    useEffect(() => {
        axios
            .get(`instituicao/${id}`)
            .then(({data}) => {
                let instituicao = (data.data)[0]
                instituicao.numero = parseInt(instituicao.numero)
                instituicao.tipo_instituicao_id = parseInt(instituicao.tipo_instituicao_id)
                setDados(instituicao)
            })
            .catch(err => {
                console.log(err)
            })
    }, [id])

    useEffect(() => {
        axios.get("/tipoInstituicao")
             .then(data => {
                 const list = data.data.data

                 setTipoInstituicaoList(list)
             })
             .catch(err => {
                 swal("Falha ao carregar tipos de instituição.")
             })
    }, [])

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Instituição Educacional</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="INEP" size="small" fullWidth error={errors.inep ? true : false} value={dados.inep} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    inep: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Tipo" variant="outlined" size="small" error={errors.tipo_instituicao_id ? true : false} value={dados.tipo_instituicao_id} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    tipo_instituicao_id: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    tipoInstituicaoList.map(tipoInst => (
                                        <MenuItem value={tipoInst.id} key={tipoInst.id}>{tipoInst.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Logradouro" size="small" fullWidth error={errors.logradouro ? true : false} value={dados.logradouro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    logradouro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Número" size="small" fullWidth error={errors.numero ? true : false} value={dados.numero} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    numero: parseInt(event.target.value)
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Bairro" size="small" fullWidth error={errors.bairro ? true : false} value={dados.bairro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    bairro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CEP" size="small" fullWidth error={errors.cep ? true : false} value={dados.cep} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cep: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 1" size="small" fullWidth error={errors.telefone1 ? true : false} value={dados.telefone1} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone1: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 2" size="small" fullWidth error={errors.telefone2 ? true : false} value={dados.telefone2} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone2: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone 3" size="small" fullWidth error={errors.telefone3 ? true : false} value={dados.telefone3} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone3: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => save()}>
                          Salvar
                        </Button>&nbsp;
                        <Button variant="contained" color="default" disabled={diretorDisabled} onClick={() => {
                            history.push(`/instituicaoEducacional/1/cadastrarDiretor`)
                            // history.push(`instituicaoEducacional/${dados.id}/cadastrarDiretor`)
                        }}>
                          Cadastrar Gestor Educacional
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}