import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import style from './style'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { Feedback, RemoveRedEye } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import { Tooltip } from '@material-ui/core'

export default function Notificacao(){
    const classes = style()
    const [notificacoes, setNotificacoes] = useState([])
    const tipo_acesso = localStorage.getItem("tipo_acesso")

    useEffect(() => {
        axios
            .get("notificacao")
            .then(({data}) => setNotificacoes(data.data))
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar dados. Recarregue a página.")
            })
    }, [])

    const history = useHistory()
    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Fichas de Notificação e Identificação</Typography>

            <div className={classes.containerButton}>
                <Button variant="contained" color="primary" onClick={() => {
                    history.push("notificacao/cadastrar")
                }}>
                  Criar nova
                </Button>
            </div>

            <p>{ notificacoes.length } { notificacoes.length === 1 ? "Notificação" : "Notificações" }</p>

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Código</TableCell>
                            <TableCell align="center">Aluno</TableCell>
                            <TableCell align="center">Data Identificação</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            notificacoes.map((row) => (
                                <TableRow key={row.id} className={
                                    tipo_acesso === 'conselheiro'? row.quantidadeFeedbacks > 0 ? classes.temFeedback : classes.semFeedback : null
                                }>
                                    <TableCell component="td" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>{row.aluno.nome}</TableCell>
                                    <TableCell>{row.dataIdentificacao}</TableCell>
                                    <TableCell>
                                        {
                                            tipo_acesso === 'conselheiro' && (
                                                    <Tooltip title="Enviar Feedback">
                                                        <IconButton onClick={() => history.push(`/notificacao/${row.id}/feedback`)}>
                                                            <Feedback color="primary" />
                                                        </IconButton>
                                                    </Tooltip>
                                            )
                                        }
                                        <Tooltip title="Visualizar">
                                            <IconButton onClick={() => history.push(`/notificacao/${row.id}/visualizar`)} >
                                                <RemoveRedEye />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            tipo_acesso === "diretor" && (
                                                <>
                                                    <Tooltip title="Excluir">
                                                        <IconButton aria-label="" onClick={() => {
                                                            Swal.fire({
                                                                title: 'Tem certeza que deseja excluir?',
                                                                text: "Você não poderá desfazer esta ação!",
                                                                icon: 'warning',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#3085d6',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Sim!'
                                                            }).then((result) => {
                                                                if (result.value) {
                                                                    axios
                                                                        .delete(`notificacao/${row.id}`)
                                                                        .then(() => {
                                                                            const newNotificacoes = notificacoes.filter(not => not.id !== row.id)
                                                                            setNotificacoes(newNotificacoes)
                                                                            Swal.fire(
                                                                            'Excluído!',
                                                                            'Registro excluído com sucesso..',
                                                                            'success'
                                                                            )
                                                                        })
                                                                        .catch(err => {
                                                                            console.log(err)
                                                                            Swal.fire("Falha ao excluir. Tente novamente.")
                                                                        })
                                                                }
                                                            })
                                                        }}>
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            )) 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}