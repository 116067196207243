const INITIAL_STATE = {
    open: true
}

export default function Sidebar(state = INITIAL_STATE, action){
    switch(action.type){
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                open: !state.open
            }
        default:
            return state
    }
}   