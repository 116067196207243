import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles((theme) => (
    {
        formGroup : {
            marginBottom: 20,
            marginTop: 30,
            border: 1,
            borderColor: '#cecece',
            borderStyle: 'solid',
            padding: 10,
            borderRadius: 10
        },
        selectedItem: {
            border: 1,
            borderColor: '#cecece',
            borderStyle: 'solid',
            padding: 10,
            margin: 0
        },
        titleContainer: {
            backgroundColor: "#00B17C",
            padding: 10,
            margin:  0,
            textAlign: 'center',
            fontWeight: 'bold'
        }
    }
))