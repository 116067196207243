import React from 'react'
import { TextField, Box, MenuItem, Tooltip } from '@material-ui/core'

export default function ({ label, error, onChange, value, items, ...params }){
    return (
        <Box component="div" m={4}>
            <TextField 
                variant="outlined" 
                select
                label={label} 
                size="small" 
                fullWidth 
                error={error} 
                value={value} 
                onChange={onChange}
                {...params}
            >
                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                {
                    items.map(item => {
                        if(item.tooltip != null){
                            return (
                                <Tooltip key={item.id} value={item.id} title={item.tooltip}>
                                    <MenuItem value={item.id} >{item.label}</MenuItem>
                                </Tooltip>
                            )
                        }else{
                            return (
                                <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                            )
                        }
                    })
                }
            </TextField>
        </Box>
    )
}