import React, { useState, useEffect, useRef } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { useParams } from 'react-router-dom'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import ReactToPrint from 'react-to-print'
import { Button } from '@material-ui/core'


export default function VisualizarNotificacao({id}){
    const classes = style()
    const [notificacao, setNotificacao] = useState(null)

    const params = useParams()

    if(!id){
        id = params.id
    }

    useEffect(() => {
        axios
            .get("notificacao/" + id)
            .then(({data}) => {
                console.log(data.data)
                setNotificacao((data.data)[0])
            })
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar dados. Recarregue a página.")
            })
    }, [id])

    const componentRef = useRef()
    if(!notificacao){
        return (
            <Typography className={classes.title} variant="h1" >Dados da Notificação</Typography>
        )
    }

    
    return (
        <>
            <div className={classes.containerButton}>
                <ReactToPrint 
                    trigger={() => <Button variant="contained" color="primary">Imprimir</Button>}
                    content={() => componentRef.current}
                />
            </div>
            <div className={classes.containerNotificacao} ref={componentRef}>
            <Typography className={classes.title} variant="h1" >Dados da Notificação</Typography>
                <p>Aluno: {notificacao.aluno.nome} </p>
                <div className={classes.alunoContainer}>
                    <p>CPF: {notificacao.aluno.cpf}</p>
                    <p>Nascimento: {notificacao.aluno.nascimento}</p>
                    <p>Mãe: {notificacao.aluno.mae}</p>
                    <p>Pai: {notificacao.aluno.pai}</p>
                    <p>Vínculo Responsável: {notificacao.aluno.vinculoResponsavelText} {notificacao.aluno.vinculoResponsavelOutro}</p>
                    <p>Responsável: {notificacao.aluno.responsavel}</p>
                    <p>Reside Com: {notificacao.aluno.resideComText} {notificacao.aluno.resideComOutro}</p>
                    <p>Rua: {notificacao.aluno.rua}</p>
                    <p>Número: {notificacao.aluno.numero}</p>
                    <p>Bairro: {notificacao.aluno.bairro}</p>
                    <p>Estado: {notificacao.aluno.estado}</p>
                    <p>Cidade: {notificacao.aluno.cidade}</p>
                    <p>Cep: {notificacao.aluno.cep}</p>
                    <p>Complemento: {notificacao.aluno.complemento}</p>
                    <p>Referência: {notificacao.aluno.referencia}</p>
                    <p>Telefone1: {notificacao.aluno.telefone1}</p>
                    <p>Telefone2: {notificacao.aluno.telefone2}</p>
                    <p>Telefone3: {notificacao.aluno.telefone3}</p>
                    <p>Deficiência: {notificacao.aluno.deficienciaText} {notificacao.aluno.deficienciaOutro}</p>
                    <p>Ano Matriculado: {notificacao.aluno.anoMatriculado}</p>
                    <p>Período de Estudo: {notificacao.aluno.periodoEstudo}</p>
                    <p>Tempo Na Instituição: {notificacao.aluno.tempoNaInstituicao}</p>
                    <p>Instituição: {notificacao.aluno.instituicaoText}</p>
                    <p>Revitimização: {notificacao.aluno.revitimizacao}</p>
                </div>
    
                <p>Data de identificação: {notificacao.dataIdentificacao}</p>
                <p>Data da notificação: {notificacao.dataNotificacao}</p>
    
                <p>Origem da Notificação: {notificacao.origemViolencia}</p>
                <div>Tipo de Violência: {notificacao.tipoViolenciaText.map(tipo => {
                    return (
                        <p key={`${tipo.tipo} - ${tipo.tipologia} - ${tipo.subtipologia}`}>{`${tipo.tipo} - ${tipo.tipologia} - ${tipo.subtipologia}`}</p>
                    )
                })}</div>
    
                <Typography className={classes.subtitle} variant="subtitle1">Dados da identificação</Typography>
    
                <Typography variant="subtitle1">Sinais comportamentais</Typography>
                {
                    notificacao.sinaisComportamentais.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <Typography variant="subtitle1">Sinais de aprendizagem</Typography>
                {
                    notificacao.sinaisAprendizagem.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <Typography variant="subtitle1">Sinais Físicos</Typography>
                {
                    notificacao.sinaisFisicos.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <Typography variant="subtitle1">Sinais de Relacionamento</Typography>
                {
                    notificacao.sinaisRelacionamento.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <p>Provável autor: {notificacao.provavelAutor + " " + notificacao.outroProvavelAutor}</p>
                <p>Local que ocorre a violência: {notificacao.localViolencia}</p>
                <p>Nome do provável autor: {notificacao.nomeProvavelAutor}</p>
                <p>Faixa Etária do provável autor: {notificacao.faixaEtariaProvavelAutor}</p>
    
                <Typography className={classes.subtitle} variant="subtitle1">Vulnerabilidades no grupo familiar</Typography>
    
                {
                    notificacao.vulnerabilidadesFamilia.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <Typography className={classes.subtitle} variant="subtitle1">Práticas Escolares</Typography>
    
                <p>Ações executadas</p>
                {
                    notificacao.acoesInstituicaoEducacionalNotificacao.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <p>Atitudes norteadores para as ações</p>
                {
                    notificacao.atitudesNorteadorasNotificacao.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <p>Serviços que a criança ou adolescente já foi encaminhada</p>
                {
                    notificacao.servicosNotificacao.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <p>Órgãos de defesa que a criança ou adolescente está inserida</p>
                {
                    notificacao.orgaosNotificacao.map(sinal => (
                        <p className={classes.sinal} key={sinal.id}>{sinal.descricao}</p>
                    ))
                }
    
                <Typography className={classes.subtitle} variant="subtitle1">Comentários adicionais</Typography>
                <p>{notificacao.comentarios}</p>
                
            </div>
        </>
    )
}