import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import LocalStorage from '../../Services/LocalStorage'

export default function PrivateRoute({children, tipo_acesso, ...rest}){
    const isAuthenticated = LocalStorage.get("token")
    const tipo_acesso_logado = LocalStorage.get("tipo_acesso")
    return (
        <Route
            {...rest}
            render={({location}) => {
                if(isAuthenticated){
                    if(!tipo_acesso || tipo_acesso === tipo_acesso_logado){
                        return (
                            children
                        )
                    }else{
                        return (
                            <Redirect
                                to={{
                                    pathname: '/',
                                    state: { from: location }
                                }}
                            />
                        )
                    }
                }else{
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    )
                }
            }}
        />
    )
}