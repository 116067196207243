import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import style from './style'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../../Services/axios'
import swal from 'sweetalert2'

export default function Diretor(){
    const classes = style()
    
    const [diretores, setDiretores] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios
            .get("diretor")
            .then(({data}) => {
                setDiretores(data.data)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const history = useHistory()
    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Gestores Educacionais</Typography>

            <div className={classes.containerButton}>
                <Button variant="contained" color="primary" onClick={() => {
                    history.push("diretor/cadastrar")
                }}>
                  Criar novo
                </Button>
            </div>

            <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Matrícula</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Escola</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading ? (
                                <TableRow>
                                    <TableCell>Carregando...</TableCell>
                                </TableRow>
                            ) : null
                        }
                        {
                            diretores.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="td" scope="row">
                                        {row.matricula}
                                    </TableCell>
                                    <TableCell>{row.nome}</TableCell>
                                    <TableCell>{row.instituicao.nome}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="" onClick={() => {
                                            swal.fire({
                                                title: 'Tem certeza que deseja excluir?',
                                                text: "Você não poderá desfazer esta ação!",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Sim!'
                                            }).then((result) => {
                                                if (result.value) {
                                                    axios
                                                        .delete(`diretor/${row.id}`)
                                                        .then(() => {
                                                            const newDiretores = diretores.filter(diretor => diretor.id !== row.id)
                                                            setDiretores(newDiretores)
                                                            swal.fire(
                                                              'Excluído!',
                                                              'Registro excluído com sucesso..',
                                                              'success'
                                                            )
                                                        })
                                                        .catch(err => {
                                                            console.log(err)
                                                            swal.fire("Falha ao excluir. Tente novamente.")
                                                        })
                                                }
                                            })
                                        }}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                        <IconButton aria-label="" onClick={() => {
                                            history.push(`/diretor/${row.id}/editar`)
                                        }}>
                                            <EditIcon color="action" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )) 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}