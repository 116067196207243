import React from 'react'
import ReactDOM from 'react-dom'
import App from './Components/App'
import Login from './Pages/Login'
import { Provider } from 'react-redux'

import store from './Redux/Store'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import PrivateRoute from './Components/PrivateRoute'

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <PrivateRoute path="/">
            <App />
          </PrivateRoute>
        </Switch>
      </Router>
    </Provider>,
  document.getElementById('root')
)
