import React from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import HowToReg from '@material-ui/icons/HowToReg'
import Home from '@material-ui/icons/Home'
import People from '@material-ui/icons/People'
import { AttachFile, Feedback, GraphicEq } from '@material-ui/icons'

import logo from '../../Assets/gesivid_branco.png'
import mao from '../../Assets/mao.png'
import { connect } from 'react-redux'

import actions from '../../Redux/Actions/Sidebar'

import useStyles from './styles'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function Sidebar({ open, dispatch, tipo_acesso }){
    const classes = useStyles()

    const handleDrawerClose = () => {
        dispatch(actions.toggleSidebar())
    }
    const history = useHistory()

    const menuList = [
        {
            text: "Gestores Educacionais",
            icon: <AssignmentInd className={classes.item} />,
            target: "/diretor",
            tipo_acesso: "gestor"
        },
        {
            text: "Gestor Municipal",
            icon: <AssignmentInd className={classes.item} />,
            target: "/gestorMunicipal",
            tipo_acesso: "gestor"
        },
        {
            text: "Instituição Educacional",
            icon: <Home className={classes.item} />,
            target: "/instituicaoEducacional",
            tipo_acesso: "gestor"
        },
        {
            text: "Usuários",
            icon: <HowToReg className={classes.item} />,
            target: "/usuarios",
            tipo_acesso: "gestor"
        },
        {
            text: "Alunos",
            icon: <HowToReg className={classes.item} />,
            target: "/alunos"
        },
        {
            text: "Conselho Tutelar",
            icon: <Home className={classes.item} />,
            target: "/conselho",
            tipo_acesso: "gestor"
        },
        {
            text: "Conselheiro Tutelar",
            icon: <People className={classes.item} />,
            target: "/conselheiro",
            tipo_acesso: "gestor"
        },
        {
            text: "Notificação e Identificação",
            icon: <AttachFile className={classes.item} />,
            target: "/notificacao"
        },
        {
            text: "Feedbacks",
            icon: <Feedback className={classes.item} />,
            target: "/feedback"
        },
        {
            text: "Estatísticas",
            icon: <GraphicEq className={classes.item} />,
            target: "/estatisticas"
        }
    ]
    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon
                        style={{
                            color: "#FFFFFF"
                        }}
                    />
                </IconButton>
            </div>
            <img src={open ? logo : mao} className={classes.logo} alt="sivid" />
            <Divider className={classes.divider} />
            <List>
                {
                    menuList.map(menu => {
                        if(tipo_acesso === menu.tipo_acesso || !menu.tipo_acesso){
                            return (
                                <ListItem key={menu.text} button onClick={() => {
                                    history.push(menu.target)
                                }}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.text} className={classes.item} />
                                </ListItem>
                            )
                        } else{
                            return null
                        }
                    })
                }
            </List>
        </Drawer>
    )
}

export default connect((state) => {
    return (
        {
            open: state.Sidebar.open
        }
    )
})(Sidebar)