function save(key, data){
    localStorage.setItem(key, data)
}

function get(key){
    return localStorage.getItem(key)
}

function clear(){
    localStorage.clear()
}

export default {
    save, get, clear
}