import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'

import style from './style'

import { TextField, Box, Grid, Button, MenuItem, Modal } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from '../../Services/axios'
import Swal from 'sweetalert2'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function CadastrarDiretor(props){

    const { instituicao_id, id } = useParams()
    const [openModal, setOpenModal] = useState(false)
    const classes = style()
    const [instituicaoList, setInstituicaoList] = useState([])

    useEffect(() => {
        axios
            .get("instituicao")
            .then(({data}) => {
                setInstituicaoList(data.data)
            })
            .catch(err => {
                console.log(err)
                Swal.fire("Falha ao carregar instituições. Recarregue a página.")
            })
    }, [])

    useEffect(() => {
        axios
            .get(`diretor/${id}`)
            .then(({data}) => {
                const diretor = (data.data)[0]
                diretor.instituicao_id = parseInt(diretor.instituicao_id)
                setDados(diretor)
            })
            .catch(err => {
                console.log(err)
            })
    }, [id])

    const [dados, setDados] = useState({
        instituicao_id: instituicao_id || 0,
        cpf: '',
        nome: '',
        nascimento: '',
        matricula: '',
        telefone: '',
        email: '',
        logradouro: '',
        numero: '',
        bairro: '',
        estado: '',
        cidade: '',
        aceita_termos: 0,
        complemento: ''
    })

    const validations = {
        instituicao_id: 'number|required',
        cpf: 'string|required',
        nome: 'string|required',
        nascimento: 'string|required',
        matricula: 'string|required',
        telefone: 'string|required',
        email: 'string|required',
        logradouro: 'string|required',
        numero: 'number|required',
        bairro: 'string|required',
        estado: 'string|required',
        cidade: 'string|required',
        complemento: 'string'
    }
    
    const INITIAL_ERRORS = {
        instituicao_id: 0,
        cpf: false,
        nome: false,
        nascimento: false,
        matricula: false,
        telefone: false,
        email: false,
        logradouro: false,
        numero: false,
        bairro: false,
        estado: false,
        cidade: false,
        complemento: false
    }
    const [errors, setErrors] = useState(INITIAL_ERRORS)

    const openModalAccept = () => {
        validate(dados)
        setOpenModal(true)
    }

    const validate = (dados) => {

        let localErrors = INITIAL_ERRORS

        const keys = Object.keys(validations)

        keys.forEach(key => {
            const constraints = validations[key].split('|')
            constraints.forEach(constraint => {
                const dado = dados[key]
                switch(constraint){
                    case 'string':
                        if(typeof dado !== 'string'){
                            localErrors[key] = key
                        }
                    break;

                    case 'number':
                        if(typeof dado !== 'number'){
                            localErrors[key] = key
                        }
                    break;
                    
                    case 'required':
                        if(dado === '' || dado === 0){
                            localErrors[key] = key
                        }
                    break;

                    default:
                        break;
                }
            })
        })

        setErrors({
            ...localErrors
        })
    }

    const keys = Object.keys(errors)

    function save(){
        let execute = true
        keys.forEach(key => {
            // console.log(errors)
            if(errors[key]){
                Swal.fire("Verifique os dados.")
                setOpenModal(false)
                execute = false
                return;
            }
        })

        if(execute){
            
            const data = {
                ...dados,
                aceita_termos: 1
            }
    
            const nascimento = data.nascimento.split("/")
            data.nascimento = `${nascimento[1]}/${nascimento[0]}/${nascimento[2]}`
            
            if(id){
                axios
                    .put(`diretor/${id}`, data)
                    .then(() => {
                        setOpenModal(false)
                        Swal.fire("Registro salvo com sucesso.")
                    })
                    .catch(err => {
                        console.log(err)
                        setOpenModal(false)
                        Swal.fire("Falha ao salvar. Tente novamente.")
                    })
            }else{
                axios
                    .post("diretor", data)
                    .then(({data}) => {
                        setOpenModal(false)
                        Swal.fire("Registro salvo com sucesso. A nova senha é: " + data.senha)
                    })
                    .catch(err => {
                        console.log(err)
                        setOpenModal(false)
                        Swal.fire("Falha ao salvar. Tente novamente.")
                    })
            }
        }
    }

    return (
        <div>
            <Typography className={classes.title} variant="h1" >Cadastro de Gestor Educacional</Typography>

            {
                keys.map(err => {
                    if(errors[err]){
                        return (
                            <Typography key={err} color="error">* Verifique o campo {errors[err]}</Typography>
                        )
                    }
                    return null
                })
            }

            <Modal
                open={openModal}
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <div className={classes.paper}>
                    <h2 className={classes.titleModal}>Termo de confidencialidade</h2>
                    <div className={classes.termo + " text-justify"}>
                        <p>Fico obrigado a manter em ABSOLUTO SIGILO, toda e qualquer informação que tiver acesso em função das minhas atribuições profissionais referentes aos Sistema de Informação e Notificação de Violência contra a Criança e Adolescente - GE-SINVCCA, entendendo como confidencial e sigilosa toda informação relativa aos instrumentos de coleta, contendo dados de identificação das crianças, adolescentes e suas famílias. Para tanto, concordo e comprometo-me à:</p>
                        <p>
                            1. Manter sigilo escrito e verbal de todos os dados, informações técnicas e administrativas obtidas
                            ou não com minha participação, zelando pela privacidade dos indivíduos relacionados/listados
                            nesta(s) base(s) de dados;
                        </p>
                        <p>
                            2. Não divulgar, publicar e publicizar quaisquer dados ou informações que tenha tomado
                            conhecimento ou elaborado no decorrer de minhas atividades sem prévia autorização
                            institucional.
                        </p>
                        <p>
                            3. Utilizar os dados de acesso restrito mantendo a necessária cautela quando da exibição de dados
                            em tela, impressora ou gravação em meios eletrônicos, a fim de evitar que deles venham tomar
                            ciência, pessoas não autorizadas.
                        </p>
                        <p>
                            4. Não fazer cópias, registros escritos ou em mídias eletrônicas de quaisquer dados ou informações
                            que não sejam necessários, por força de minhas atribuições e responsabilidades, assim como tomar precauções e as devidas medidas de segurança, para que no âmbito de minhas atribuições e
                            responsabilidades, tais dados e informações não sejam copiados, revelados, ou venham a ser
                            usados indevidamente ou sem autorização.
                        </p>
                        <p>
                            5. Não praticar qualquer medida fora de minhas atribuições com a finalidade de obter para mim ou
                            terceiros, direitos, vantagens pessoais ou financeiras relativas às informações que tenho acesso.
                        </p>
                        <p>
                            6. Não ausentar-me da estação de trabalho sem encerrar a sessão de uso dos sistemas, garantindo
                            assim a impossibilidade de acesso indevido de terceiros.
                        </p>
                        <p>
                            7. Não revelar minha senha de acesso ao(s) sistema(s) a outros, cuidando para que a mesma seja
                            somente de meu conhecimento.
                        </p>
                        <p>
                            8. Alterar minha senha de acesso quando da possibilidade de violação ou conhecimento de terceiros
                            e, não utilizar combinações óbvias ou de fácil acesso à mesma.
                        </p>
                        <p>
                            9. Notificar imediatamente ao responsável técnico pelos referidos sistemas quando do eventual
                            desligamento das funções ou atribuições referentes a tais sistemas.
                        </p>
                        <p>
                            10. Observar e cumprir as boas práticas de segurança da informação e das diretrizes preconizadas por
                            tais sistemas de informações.
                        </p>
                        <p>
                            Declaro ainda estar ciente de que o não cumprimento de tais normas caracterizará infração
                            ética podendo acarretar punição nas esferas penal, civil e administrativa de acordo com a legislação
                            vigente. 
                        </p>
                    </div>
                    <Grid container spacing={1} justify="flex-end">
                        <Grid item xs={12}>
                            <br />
                            <Button variant="contained" color="primary" onClick={save}>
                                Aceitar
                            </Button>&nbsp;
                            <Button variant="contained" color="default" onClick={() => setOpenModal(false)}>
                                Recusar
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>

            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField fullWidth select label="Instituição Educacional" variant="outlined" size="small" error={errors.instituicao_id ? true : false} value={dados.instituicao_id} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    instituicao_id: event.target.value
                                })
                            }}>
                                <MenuItem value={0} disabled>Selecione uma opção</MenuItem>
                                {
                                    instituicaoList.map(inst => (
                                        <MenuItem value={inst.id} key={inst.id}>{inst.nome}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Nome" size="small" fullWidth error={errors.nome ? true : false} value={dados.nome} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    nome: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Matrícula" size="small" fullWidth error={errors.matricula ? true : false} value={dados.matricula} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    matricula: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="E-mail" size="small" fullWidth error={errors.email ? true : false} value={dados.email} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    email: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>  
                        <Box component="div" m={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} ref={this}>
                                <KeyboardDatePicker
                                    margin="none"
                                    id="date-picker-dialog"
                                    label="Data de nascimento"
                                    format="dd/MM/yyyy"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    variant="outlined"
                                    className={style.datePickerMargin}
                                    onChange={(date, value) => {
                                        setDados({
                                            ...dados,
                                            nascimento: value
                                        })
                                    }}
                                    error={errors.nascimento ? true : false}
                                    inputValue={dados.nascimento}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Estado" size="small" fullWidth error={errors.estado ? true : false} value={dados.estado} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    estado: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Cidade" size="small" fullWidth error={errors.cidade ? true : false} value={dados.cidade} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cidade: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Logradouro" size="small" fullWidth error={errors.logradouro ? true : false} value={dados.logradouro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    logradouro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Número" size="small" fullWidth error={errors.numero ? true : false} value={dados.numero} onChange={(event) => {
                                let numero = 0
                                if(event.target.value){
                                     numero = parseInt(event.target.value)
                                }
                                setDados({
                                    ...dados,
                                    numero
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Bairro" size="small" fullWidth error={errors.bairro ? true : false} value={dados.bairro} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    bairro: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Complemento" size="small" fullWidth error={errors.complemento ? true : false} value={dados.complemento} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    complemento: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="Telefone" size="small" fullWidth error={errors.telefone ? true : false} value={dados.telefone} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    telefone: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="div" m={4}>
                            <TextField variant="outlined" label="CPF" size="small" fullWidth error={errors.cpf ? true : false} value={dados.cpf} onChange={(event) => {
                                setDados({
                                    ...dados,
                                    cpf: event.target.value
                                })
                            }} />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={1} justify="flex-end">
                    <Grid item sx={12}>
                        <Button variant="contained" color="primary" onClick={() => openModalAccept()}>
                          Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}